import React, { Component } from 'react';
import '@styles/shared/sliderOnOff.scss'; 

export class SliderOnOff extends Component {
    static displayName = SliderOnOff.name;

    constructor(props) {
        super(props);
    }

    HandleOnChange = () => {

        if (this.props.readOnly)
            return;

        this.props.OnChange();
    }

    render() {

        let htmlFor = 'label_' + this.props.text;
        let flexDir = 'align-items-center';
        if (this.props.col)
            flexDir = 'flex-column';

        return (
            <div id="sliderOnOff" className={`${this.props.dark && 'dark'} d-flex  ${flexDir}`}>

                <label className={`${this.props.reversed ? 'order-2 ms-1' : 'me-1'}`} htmlFor={htmlFor}>
                    {this.props.text}
                </label>

                <label className={`switch`} htmlFor={htmlFor}>
                    <input id={htmlFor} type="checkbox" checked={this.props.isChecked} className={`${this.props.readOnly && 'readOnly'}`} readOnly={this.props.readOnly} onChange={() => this.HandleOnChange()} />
                    <span className={`slider round ${this.props.readOnly ? 'readOnly' : ''}`}></span>
                </label>
    
            </div>
        );
    }
}
