import React, { Component } from 'react'; 
import { Button } from '@components/shared/Button';
import { Spinner } from '@components/shared/Spinner';
import { InfoMessage } from '@components/shared/InfoMessage';
import axios from "axios";
import AdmResponseHelper from '@model/helper/AdmResponseHelper';
import { SliderOnOff } from '@components/shared/SliderOnOff';
import { ToolTip } from '@components/shared/ToolTip';

export class NewTranslation extends Component {
    static displayName = NewTranslation.name;

    constructor(props) {
        super(props);

        this.state = {
            key: "",
            keyEmpty: false,
            internalNote: "",

            page: this.props.selectedPage == 'all' ? this.props.pageList[0].id : this.props.selectedPage,

            response: null,
            loading: false,

            availableLanguages: this.props.languageList,
            selectedLanguage: this.props.languageList[0],

            localizationList: [],

            isActive: true,
            isCached: !this.props.forceCareer,

            nameConventionError: false,
        };

        this.responseHelper = new AdmResponseHelper();
        this.loginHeader = {
            headers: {
                'Header-Authority': JSON.stringify({ Token: this.props.loggedAdmin.loginToken })
            }
        };
    }

    componentDidMount() {
        document.body.classList.add('noScroll');
    }

    componentWillUnmount() {
        document.body.classList.remove('noScroll');
    }

    IsPageCareer = () => {
        let currentPage = this.props.pageList.find(p => p.id == this.state.page);
        return currentPage?.name == 'career';
    }

    HandleSetKey = (val) => {
        this.HandleClearResponses();
        this.setState((prevState) => ({ ...prevState, key: val }));
    }

    HandleSetInternalNote = (val) => {
        this.HandleClearResponses();
        this.setState((prevState) => ({ ...prevState, internalNote: val }));
    }

    HandleSetPage = (val) => {
        this.HandleClearResponses();
        this.setState((prevState) => ({ ...prevState, page: val }));
    }

    HandleSelectLanguage = (id) => {
        this.HandleClearResponses();
        let lang = this.props.languageList.find(l => l.id == id);
        this.setState((prevState) => ({ ...prevState, selectedLanguage: lang }));
    }

    HandleAddNewLanguageTranslation = (id) => {
        this.HandleClearResponses();
        let localizationList = this.state.localizationList;
        localizationList.push({
            IdLanguage: id,
            Value: ""
        });

        let availableLanguages = this.props.languageList.filter(l => !localizationList.some(t => t.IdLanguage === l.id));
        let selectedLanguage = availableLanguages.length > 0 ? availableLanguages[0] : null;
        this.setState((prevState) => ({ ...prevState, localizationList: localizationList, availableLanguages: availableLanguages, selectedLanguage: selectedLanguage }));
    }

    HandleRemoveNewLanguageTranslation = (id) => {
        this.HandleClearResponses();
        let localizationList = this.state.localizationList;
        localizationList = localizationList.filter(t => t.IdLanguage != id);

        let availableLanguages = this.props.languageList.filter(l => !localizationList.some(t => t.IdLanguage == l.id));
        let selectedLanguage = availableLanguages.length > 0 ? availableLanguages[0] : null;

        this.setState((prevState) => ({ ...prevState, localizationList: localizationList, availableLanguages: availableLanguages, selectedLanguage: selectedLanguage }));
    }

    HandleChangeLanguageTranslationValue = (id, val) => {
        this.HandleClearResponses();
        let localizationList = this.state.localizationList;
        let translation = localizationList.find(l => l.IdLanguage == id);
        translation.Value = val;

        this.setState((prevState) => ({ ...prevState, localizationList: localizationList }));
    }

    HandleToggleActive = () => {
        this.HandleClearResponses();
        this.setState((prevState) => ({ ...prevState, isActive: !this.state.isActive }));
    }

    HandleToggleCached = () => {
        this.HandleClearResponses();
        this.setState((prevState) => ({ ...prevState, isCached: !this.state.isCached }));
    }

    HandleClearResponses = () => {
        this.setState((prevState) => ({
            ...prevState,
            response: null,
            keyEmpty: false,
            nameConventionError: false
        }));
    }

    CreateTranslation = () => {

        let keyEmpty = this.state.key.length <= 0;
        let targetPage = this.props.pageList.find(p => p.id == this.state.page);
        let nameConventionError = !this.state.key.startsWith(targetPage.translationPrefix);

        this.setState((prevState) => ({ ...prevState, loading: !(keyEmpty || nameConventionError), keyEmpty: keyEmpty, nameConventionError: nameConventionError }));
        if (keyEmpty || nameConventionError)
            return;

        axios
            .post("/api/adm/translation/create", {
                Key: this.state.key,
                IdPage: parseInt(this.state.page),
                LocalizationList: this.state.localizationList,
                IsActive: this.state.isActive,
                IsCached: this.IsPageCareer() ? this.state.isCached : true,
                InternalNote: this.state.internalNote
            }, this.loginHeader)
            .then((resp) => {
                let data = resp.data;

                this.setState((prevState) => ({
                    ...prevState,
                    response: data.responseCode
                }));

                if (data.responseCode === 200) {
                    setTimeout(() => {
                        this.props.HandleCloseForm();
                    }, 600);
                    this.props.GetTranslationList();
                }

            })
            .catch(() => {
                this.setState((prevState) => ({ ...prevState, response: 500 }));
            })
            .finally(() => {
                this.setState((prevState) => ({ ...prevState, loading: false }));
            });
    }

    HandleClickLanguageImage = (e) => {
        let select = document.getElementById('language');
        select.focus();
    }

    RenderNameConventionError = () => {

        let targetPage = this.props.pageList.find(p => p.id == this.state.page);
        return (
            < InfoMessage text={`Nesprávný prefix klíče překladu! Požadovaný prefix: \"${targetPage.translationPrefix}\"`} stayVisible />
        );
    }

    render() {
        return (
            <div className='form border rounded p-3 fitH'>
                <div className='d-flex align-items-center justify-content-between mb-3'>
                    <h2 className='m-0'>Přidat překlad</h2>
                    <div className='d-flex justify-content-end'>
                        <Button text={<i className="fa-solid fa-xmark"></i>} OnClick={() => this.props.HandleCloseForm()} />
                    </div>
                </div>
                <div className='position-relative'>
                    {this.state.loading &&
                        <Spinner absolute whiteBg />
                    }
                    <div className='d-flex align-items-center justify-content-start mb-2 col-12 '>
                        <div className='ps-2 d-flex justify-content-center'>
                            <SliderOnOff text={'Aktivní'} isChecked={this.state.isActive} OnChange={() => this.HandleToggleActive()} />
                        </div>
                        {this.IsPageCareer() ?
                            <div className='ms-4 ps-2 d-flex justify-content-center'>
                                <SliderOnOff text={'Překlad kariéry'} col isChecked={!this.state.isCached} OnChange={() => this.HandleToggleCached()} disabled={this.props.forceCareer} />
                                <div className='ms-1 w-auto'>
                                    <ToolTip text={'Překlad vázaný na konkrétní kariéru. Např. překlad názvu kariéry, popisu atd. Nenačítá se s normálními překlady, protože se z db načítá rovnou přeložený při požadavku o konkrétní kariéru. Tento překlad není určen ke standardnímu použití na stránce.'} />
                                </div>
                            </div>
                            :
                            <div className='col'></div>
                        }
                    </div>
                    <div className='d-flex flex-column flex-md-row justify-content-md-between align-items-md-end'>
                        <div className={`d-flex flex-column mb-3 mb-md-0 col-12 col-md-7 ${(this.state.keyEmpty || this.state.nameConventionError) && 'inputError'}`}>
                            <label htmlFor='key'>Key</label>
                            <input type='text' id='key' className='ps-2' value={this.state.key} onChange={(e) => this.HandleSetKey(e.target.value)} />
                        </div>
                        <div className={`d-flex flex-column mb-3 col-12 col-md-4 mb-md-0`}>
                            <label htmlFor='page'>Stránka</label>
                            <select type='text' id='page' className={`${this.props.forceCareer && 'disabled'} ps-2`} value={this.state.page} disabled={this.props.forceCareer} onChange={(e) => this.HandleSetPage(e.target.value)}>
                                {this.props.pageList.map(page => {
                                    return (
                                        <option key={page.id} value={page.id}>{`${page.name} (\"${page.translationPrefix}\") ${!page.isActive ? '(neaktivní)' : ''}`}</option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <div className={`d-flex flex-column mb-3 col-12`}>
                        <label htmlFor='note'>Interní popis</label>
                        <textarea type='text' id='note' className='ps-2' value={this.state.internalNote} onChange={(e) => this.HandleSetInternalNote(e.target.value)} />
                    </div>
                    {this.state.availableLanguages.length > 0 &&
                        <div className='d-flex align-items-center col'>
                            <div className='d-flex flex-column w-100 flex-sm-row align-items-sm-end'>
                                <div className={`me-3 position-relative d-flex flex-column col-12 col-sm-auto mb-2 mb-sm-0`}>
                                    <label htmlFor='language'>Přidat lokalizaci</label>
                                    <select type='text' id='language' className='p-1 ps-2 pe-5' value={this.state.selectedLanguage.id} onChange={(e) => this.HandleSelectLanguage(e.target.value)}>
                                        {this.state.availableLanguages.map(language => {
                                            return (
                                                <option key={language.id} value={language.id}>{language.code.toUpperCase()} - {language.name} {language.isDefault && '(výchozí)'} {!language.isActive && '(neaktivní)'}</option>
                                            );
                                        })}
                                    </select>
                                    {this.state.selectedLanguage.imgPath.length > 0 &&
                                        <img className='translationLangNew position-absolute' src={`/files/images/${this.state.selectedLanguage.imgPath}`} alt={`Vlajka jazyka ${this.state.selectedLanguage.name}`} onClick={(e) => this.HandleClickLanguageImage(e)} />    
                                    }
                                </div>
                                <Button text={'Přidat'} OnClick={() => this.HandleAddNewLanguageTranslation(this.state.selectedLanguage.id)} small />
                            </div>
                        </div>
                    }
                    <div>
                        {this.state.localizationList.map(t => {

                            let lang = this.props.languageList.find(l => l.id == t.IdLanguage);

                            return (
                                <div className='translation d-flex flex-column border-top p-2 px-3 my-2' key={lang.id}>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className={`position-relative me-3 d-flex flex-column mb-3 col`}>
                                            <label htmlFor='lang'>Jazyk <i className="ms-1 fa-solid fa-lock"></i></label>
                                            <input type='text' id='lang' className='ps-2 readonly' readOnly value={lang.name} />
                                            <img className='translationLangNew position-absolute' src={`/files/images/${lang.imgPath}`} alt={`Vlajka jazyka ${lang.name}`} />
                                        </div>
                                        <Button text={'Odstranit jazyk'} OnClick={() => this.HandleRemoveNewLanguageTranslation(lang.id)} small />
                                    </div>
                                    <div className={`d-flex flex-column mb-3 col-12`}>
                                        <label htmlFor='value'>Překlad</label>
                                        <textarea type='text' id='value' className='ps-2' value={t.Value} onChange={(e) => this.HandleChangeLanguageTranslationValue(lang.id, e.target.value)} />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className='d-flex justify-content-between pt-3'>
                        <div className='d-flex flex-column responses'>
                            {this.responseHelper.RenderCreateResponse(this.state.response)}
                            {this.state.keyEmpty && <InfoMessage text={'Vyplňte klíč!'} />}
                            {this.state.nameConventionError && this.RenderNameConventionError()}
                        </div>
                        <Button className='col-2' text={"Přidat"} OnClick={() => this.CreateTranslation()} />
                    </div>
                </div>
            </div>
        );
    }

}
