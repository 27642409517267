import React from 'react';
import { createRoot } from 'react-dom/client';
import { AppRouter } from './AppRouter';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import '@styles/shared/shared.scss';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
    <AppRouter />
);

reportWebVitals();