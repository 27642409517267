import React, { Component } from 'react';
import '@styles/shared/tooltip.scss'; 

export class ToolTip extends Component {
    static displayName = ToolTip.name;

    constructor(props) {
        super(props);
        this.state = {
            opened: false
        }
    }

    HandleSetOpened = () => {
        this.setState((prevState) => ({ ...prevState, opened: true }));
    }

    HandleSetClosed = () => {
        this.setState((prevState) => ({ ...prevState, opened: false }));
    }

    render() {

        return (
            <div className={`mytooltip `} onMouseOver={() => this.HandleSetOpened()} onClick={() => this.HandleSetOpened()} onMouseLeave={() => this.HandleSetClosed()}>
                <div className='border p-1 icon position-relative'>
                    <i className="fa-solid fa-info position-absolute"></i>
                </div>
                {this.state.opened &&
                    <div className={`text position-fixed p-2 border rounded ${this.props.gray ? 'gray' : ''}`} onTouchStart={() => this.HandleSetClosed()} onMouseOver={() => this.HandleSetClosed()} onClick={() => this.HandleSetClosed()} onMouseLeave={() => this.HandleSetClosed()}>
                        <div className='position-relative d-flex flex-column flex-md-row justify-content-start align-items-start'>
                            <div className='fw-bold me-1'>
                                ToolTip:
                            </div>
                            {this.props.text}
                            <i className="fa-solid fa-x position-absolute close d-md-none"></i>
                        </div>
                    </div>
                }
            </div>
        );
    }
}
