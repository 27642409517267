
import BaseResponse from '@model/response/BaseResponse';

class CareerDetailResponse extends BaseResponse {

    constructor(career, responseCode) {

        super(responseCode);
        this.career = career
    }
    
}

export default CareerDetailResponse;