import React, { Component } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PageController as PublicController } from "@components/public/PageController";
import { PageController as AdmController } from "@components/adm/PageController";

export class AppRouter extends Component {
    static displayName = AppRouter.name;

    render() {

        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/">
                                             
                        <Route index element={
                            <PublicController page={'home'} />
                        } />

                        <Route path="/kariera/:name" element={
                            <PublicController page={'career'} />
                        } />

                        <Route path="/career/:name" element={
                            <PublicController page={'career'} />
                        } />

                        <Route path="/karriere/:name" element={
                            <PublicController page={'career'} />
                        } />

                        <Route path="/automatizovane-vydejny" element={
                            <PublicController page={'automatedDispensaries'} />
                        } />

                        <Route path="/automated-dispensaries" element={
                            <PublicController page={'automatedDispensaries'} />
                        } />

                        <Route path="/werkzeugautomaten" element={
                            <PublicController page={'automatedDispensaries'} />
                        } />

                        <Route path="/prospekty" element={
                            <PublicController page={'brochures'} />
                        } />

                        <Route path="/brochures" element={
                            <PublicController page={'brochures'} />
                        } />

                        <Route path="/broschuren" element={
                            <PublicController page={'brochures'} />
                        } />

                        <Route path="/prodejni-automaty" element={
                            <PublicController page={'vendingMachines'} />
                        } />

                        <Route path="/vending-machines" element={
                            <PublicController page={'vendingMachines'} />
                        } />

                        <Route path="/verkaufsautomat" element={
                            <PublicController page={'vendingMachines'} />
                        } />

                        {/*<Route path="/zalohovaci-automaty" element={*/}
                        {/*    <PublicController page={'reverseVendingMachines'} />*/}
                        {/*} />*/}

                        {/*<Route path="/reverse-vending-machines" element={*/}
                        {/*    <PublicController page={'reverseVendingMachines'} />*/}
                        {/*} />*/}

                        {/*<Route path="/rucknahmesysteme" element={*/}
                        {/*    <PublicController page={'reverseVendingMachines'} />*/}
                        {/*} />*/}

                        {/*<Route path="/guest" element={*/}
                        {/*    <PublicController page={'guest'} />*/}
                        {/*} />*/}

                        <Route path="_adm">

                            <Route index element={
                                <AdmController page={'dashboard'} />
                            } />

                            <Route path="translations" element={
                                <AdmController page={'translations'} />
                            } />

                            <Route path="users" element={
                                <AdmController page={'users'} />
                            } />

                            <Route path="careers" element={
                                <AdmController page={'careers'} />
                            } />

                            <Route path="languages" element={
                                <AdmController page={'languages'} />
                            } />

                            <Route path="pages" element={
                                <AdmController page={'pages'} />
                            } />

                            <Route path="images" element={
                                <AdmController page={'images'} />
                            } />

                            <Route path="documents" element={
                                <AdmController page={'documents'} />
                            } />

                            <Route path="slideShow" element={
                                <AdmController page={'slideShow'} />
                            } />

                            <Route path="*" element={
                                <PublicController page={'notFound'} />
                            } />

                        </Route>

                        <Route path="*" element={
                            <PublicController page={'notFound'} />
                        } />

                    </Route>
                </Routes>
            </BrowserRouter>
        );
    }
}
