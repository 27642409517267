import React, { Component } from 'react'; 
import { Button } from '@components/shared/Button';
import { Spinner } from '@components/shared/Spinner';
import { InfoMessage } from '@components/shared/InfoMessage';
import axios from "axios";
import { SliderOnOff } from '@components/shared/SliderOnOff';
import AdmResponseHelper from '@model/helper/AdmResponseHelper';
import { ToolTip } from '@components/shared/ToolTip';

export class NewUser extends Component {
    static displayName = NewUser.name;

    constructor(props) {
        super(props);

        this.defaultLoginDuration = 3;
        this.state = {
            idRole: this.props.userRoleList[0].id,
            username: "",
            usernameEmpty: false,
            password: "",
            passwordTooShort: false,
            passwordVisible: false,

            loginDuration: this.defaultLoginDuration,

            isActive: true,
            description: "",

            response: null,
            loading: false
        };

        this.minPasswordLength = 6;
        this.responseHelper = new AdmResponseHelper();
        this.loginHeader = {
            headers: {
                'Header-Authority': JSON.stringify({ Token: this.props.loggedAdmin.loginToken })
            }
        };
    }

    componentDidMount() {
        document.body.classList.add('noScroll');
    }

    componentWillUnmount() {
        document.body.classList.remove('noScroll');
    }

    HandleTogglePassword = () => {
        this.setState((prevState) => ({ ...prevState, passwordVisible: !this.state.passwordVisible, response: null }));
    }

    HandleToggleIsActive = () => {
        this.setState((prevState) => ({ ...prevState, isActive: !this.state.isActive, response: null }));
    }

    HandleSetUsername = (val) => {
        this.setState((prevState) => ({ ...prevState, username: val, usernameEmpty: false, response: null }));
    }

    HandleSetPassword = (val) => {
        this.setState((prevState) => ({ ...prevState, password: val, passwordTooShort: false, response: null }));
    }

    HandleSetIdRole = (val) => {
        this.setState((prevState) => ({ ...prevState, idRole: val, response: null }));
    }

    HandleSetLoginDuration = (val) => {

        let duration;
        try {
            duration = parseInt(val);
            duration = Math.round(duration);
            if (duration < 1 || duration == undefined || isNaN(duration))
                duration = 1;
        } catch {
            duration = this.defaultLoginDuration;
        }

        this.setState((prevState) => ({ ...prevState, loginDuration: duration, response: null }));
    }

    CreateUser = () => {

        let usernameEmpty = this.state.username.length <= 0;
        let passwordTooShort = this.state.password.length < this.minPasswordLength;
        this.setState((prevState) => ({
            ...prevState,
            usernameEmpty: usernameEmpty,
            passwordTooShort: passwordTooShort,
            response: null,
            loading: (!usernameEmpty && !passwordTooShort)
        }));

        if (usernameEmpty || passwordTooShort)
            return;

        axios
            .post("/api/adm/user/create", {
                Username: this.state.username,
                IdRole: this.state.idRole,
                Password: this.state.password,
                Description: this.state.description,
                IsActive: this.state.isActive,
                LoginDuration: this.state.loginDuration,
            }, this.loginHeader)
            .then((resp) => {
                let data = resp.data;

                this.setState((prevState) => ({ ...prevState, response: data.responseCode }));
                if (data.responseCode == 200) {
                    this.props.GetUserList();
                    setTimeout(() => {
                        this.props.HandleCloseForm();
                    }, 200);
                }
            })
            .catch(() => {
                this.setState((prevState) => ({ ...prevState, response: 500 }));
            })
            .finally(() => {
                this.setState((prevState) => ({ ...prevState, loading: false }));
            });
    }

    render() {
        return (
            <div className='form border rounded p-3 fitH position-relative'>

                <div className='d-flex align-items-center justify-content-between mb-3'>
                    <h2 className='m-0'>Přidat uživatele</h2>
                    <div className='d-flex justify-content-end'>
                        <Button text={<i className="fa-solid fa-xmark"></i>} OnClick={() => this.props.HandleCloseForm()} />
                    </div>
                </div>
                <div className='position-relative'>
                    {this.state.loading &&
                        <Spinner absolute whiteBg />
                    }
                    <div className='d-flex flex-column '>
                        <div className={`d-flex flex-column mb-3 col-12 ${this.state.usernameEmpty ? 'inputError' : ''}`}>
                            <label htmlFor='username'>Username*</label>
                            <input type='text' id='username' name='username' className={`ps-2`} value={this.state.username} onChange={(e) => this.HandleSetUsername(e.target.value)} />
                        </div>
                        <div className={`d-flex flex-column mb-3 col-12`}>
                            <label htmlFor='loginDuration'>Délka přihlášení (hodiny)*</label>
                            <input type='number' id='loginDuration' className={`ps-2`} value={this.state.loginDuration} onChange={(e) => this.HandleSetLoginDuration(e.target.value)} />
                        </div>
                        <div className={`d-flex flex-column mb-3 col-12`}>
                            <label htmlFor='role'>Role</label>
                            <select type='text' id='role' className='p-1' value={this.state.idRole} onChange={(e) => this.HandleSetIdRole(e.target.value)}>
                                {this.props.userRoleList.map(role => {
                                    return (
                                        <option key={role.id} value={role.id}>{role.name}</option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className={`d-flex flex-column mb-3 col-12 position-relative ${this.state.passwordTooShort ? 'inputError' : ''}`}>
                            <label htmlFor='password'>Heslo*</label>
                            <input
                                id='password' name='password'
                                className='ps-2'
                                type={`${this.state.passwordVisible ? 'text' : 'password'}`}
                                value={this.state.password}
                                onChange={(e) => this.HandleSetPassword(e.target.value)}
                            />
                            <i role='button' className={`position-absolute pb-2 pe-1 bottom-0 end-0 fa-regular fa-eye${this.state.passwordVisible ? '-slash' : ''}`}
                                onClick={() => this.HandleTogglePassword()}
                            ></i>
                        </div>
                        <div className='mt-2 mb-3 d-flex align-items-center'>
                            <SliderOnOff text={'Aktivní'} isChecked={this.state.isActive} OnChange={() => this.HandleToggleIsActive()} />
                            <div className='ms-1 w-auto'>
                                <ToolTip text={'Deaktivováním se zamezí možnost přihlášení daného uživatele'} />
                            </div>
                        </div>
                        <div className={`d-flex flex-column mb-3 col-12`}>
                            <label htmlFor='description'>Popis</label>
                            <textarea type='text' id='description' name='description' className='ps-2' value={this.state.description} onChange={(e) => this.setState((prevState) => ({ ...prevState, description: e.target.value }))} />
                        </div>
                    </div>
                    <div className={`d-flex justify-content-between`}>
                        <div className='d-flex flex-column'>
                            {this.responseHelper.RenderCreateResponse(this.state.response)}
                            {this.state.usernameEmpty && <InfoMessage text={"Přihlašovací jméno není vyplněno!"} />}
                            {this.state.passwordTooShort && <InfoMessage text={"Heslo je příliš krátké!"} />}
                        </div>
                        <Button className='col-2' text={"Přidat"} OnClick={() => this.CreateUser()} />
                    </div>
                </div>
            </div>
        );
    }

}
