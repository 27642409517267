import React, { Component } from 'react';
import '@styles/public/home/vendingMachines.scss'; 
import { Button } from '@components/shared/Button';

export class VendingMachines extends Component {
    static displayName = VendingMachines.name;

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <section className={`position-relative`} id='vendingMachines'>
                <div className='containerCustomMain'>
                    <h1>{this.props.translate("s_navlink_vending_machines")}</h1>
                </div>
                <figure className='w-100 h-100'>
                    <picture>
                        <source srcSet={`files/images/vending_machines_mobile.jpg`} media="(min-width: 0px) and (max-width: 576px)" type="image/jpg" />
                        <source srcSet={`files/images/vending_machines_tablet.jpg`} media="(min-width: 577px) and (max-width: 1200px)" type="image/jpg" />
                        <source srcSet={`files/images/vending_machines_pc.jpg`} media="(min-width: 1201px) and (max-width: 1920px)" type="image/jpg" />
                        <source srcSet={`files/images/vending_machines_pc_large.jpg`} media="(min-width: 1921px)" type="image/jpg" />
                        <img src={`files/images/vending_machines_pc.jpg`} alt={'Úvodní fotografie sekce prodejní automaty'} />
                    </picture>
                </figure>
                <div className='containerCustomMain mt-3'>
                    <div className='containerCustom'>
                        {this.props.translate("h_vm_text")}
                        <div className='mt-2'>
                            <Button iconClass={"fa-regular fa-file-pdf"} text={this.props.translate("s_show_product_program")} downloadFile={this.props.translateRaw("s_vm_sielaff_guide_brochure")} />
                        </div>
                    </div>
                    <div className='mt-4 d-flex flex-column mb-1'>
                        <div className='mb-1'>{this.props.translate("h_visit_sielaff")}</div>
                        <div className='fitW'>
                            <a href={this.props.translateRaw("s_vm_visit_sielaff_link")} rel='noreferrer' target='_blank' className='d-flex'>
                                <Button arrow text={this.props.translate("s_sielaff_link_label")} />
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
