import React, { Component } from 'react'; 
import { Button } from '@components/shared/Button';
import { Spinner } from '@components/shared/Spinner';
import { InfoMessage } from '@components/shared/InfoMessage';
import axios from "axios";
import AdmResponseHelper from '@model/helper/AdmResponseHelper';
import { SliderOnOff } from '@components/shared/SliderOnOff';

export class NewLanguage extends Component {
    static displayName = NewLanguage.name;

    constructor(props) {
        super(props);

        this.state = {
            name: "",
            code: "",
            isActive: false,
            isDefault: false,
            imgPath: "",

            response: null,
            loading: false,

            nameEmpty: false,
            codeEmpty: false,
            defaultAndNotActive: false
        };

        this.responseHelper = new AdmResponseHelper();
        this.loginHeader = {
            headers: {
                'Header-Authority': JSON.stringify({ Token: this.props.loggedAdmin.loginToken })
            }
        };
    }

    componentDidMount() {
        document.body.classList.add('noScroll');
    }

    componentWillUnmount() {
        document.body.classList.remove('noScroll');
    }

    HandleSetImgPath = (val) => {
        this.setState((prevState) => ({ ...prevState, imgPath: val, response: null }));
    }

    HandleSetName = (val) => {
        this.setState((prevState) => ({ ...prevState, name: val, response: null, nameEmpty: false }));
    }

    HandleSetCode = (val) => {
        this.setState((prevState) => ({ ...prevState, code: val, response: null, codeEmpty: false }));
    }

    HandleToggleActive = () => {
        this.setState((prevState) => ({
            ...prevState,
            isActive: !this.state.isActive,
            response: null,
            isDefault: this.state.isActive ? false : this.state.isDefault,
            defaultAndNotActive: false
        }));
    }

    HandleToggleDefault = () => {
        this.setState((prevState) => ({
            ...prevState,
            isDefault: !this.state.isDefault,
            isActive: this.state.isDefault ? this.state.isActive : true,
            response: null,
            defaultAndNotActive: false
        }));
    }

    CreateLanguage = () => {

        let defaultAndNotActive = this.state.isDefault && !this.state.isActive;
        let nameEmpty = this.state.name.length <= 0;
        let codeEmpty = this.state.code.length <= 0;

        this.setState((prevState) => ({
            ...prevState,
            loading: !defaultAndNotActive && !nameEmpty && !codeEmpty,
            defaultAndNotActive: defaultAndNotActive,
            nameEmpty: nameEmpty,
            codeEmpty: codeEmpty
        }));

        if (defaultAndNotActive || nameEmpty || codeEmpty)
            return;
            
        axios
            .post("/api/adm/language/create", {
                LoginData: { Token: this.props.loggedAdmin.loginToken },
                Name: this.state.name,
                Code: this.state.code,
                IsActive: this.state.isActive,
                IsDefault: this.state.isDefault,
                ImgPath: this.state.imgPath,
            }, this.loginHeader)
            .then((resp) => {
                let data = resp.data;

                this.setState((prevState) => ({
                    ...prevState,
                    response: data.responseCode,
                    description: "",
                    isActive: true,
                    name: "",
                    code: "",
                    isDefault: false
                }));

                if (data.responseCode == 200) {
                    this.props.GetLanguageList();
                    setTimeout(() => {
                        this.props.HandleCloseForm();
                    }, 200);
                }
            })
            .catch(() => {
                this.setState((prevState) => ({ ...prevState, response: 500 }));
            })
            .finally(() => {
                this.setState((prevState) => ({ ...prevState, loading: false }));
            });
    }

    render() {
        return (
            <div className='form border rounded p-3 fitH'>
                <div className='d-flex align-items-center justify-content-between mb-3'>
                    <h2 className='m-0'>Přidat jazyk</h2>
                    <div className='d-flex justify-content-end'>
                        <Button text={<i className="fa-solid fa-xmark"></i>} OnClick={() => this.props.HandleCloseForm()} />
                    </div>
                </div>
                <div className='position-relative'>
                    {this.state.loading &&
                        <Spinner absolute whiteBg />
                    }
                    <div className={`d-flex flex-column mb-3 col-12 ${this.state.nameEmpty && 'inputError'}`}>
                        <label htmlFor='name'>Název</label>
                        <input type='text' id='name' name='name' className='ps-2' value={this.state.name} onChange={(e) => this.HandleSetName(e.target.value)} />
                    </div>
                    <div className={`d-flex flex-column mb-3 col-12 ${this.state.codeEmpty && 'inputError'}`}>
                        <label htmlFor='code'>Kód</label>
                        <input type='text' id='code' name='code' className='ps-2' value={this.state.code} onChange={(e) => this.HandleSetCode(e.target.value)} />
                    </div>
                    <div className='d-flex align-items-center mb-2'>
                        <div className='border-end pe-3'>
                            <SliderOnOff text={'Aktivní'} isChecked={this.state.isActive} OnChange={() => this.HandleToggleActive()} />
                        </div>
                        <div className='ps-3'>
                            <SliderOnOff text={'Výchozí'} isChecked={this.state.isDefault} OnChange={() => this.HandleToggleDefault()} />
                        </div>
                    </div>
                    <div className={`d-flex flex-column mb-3 col-12`}>
                        <label htmlFor='imgPath'>Cesta obrázku</label>
                        <input type='text' id='imgPath' name='imgPath' className='ps-2' value={this.state.imgPath} onChange={(e) => this.HandleSetImgPath(e.target.value)} />
                    </div>
                    <div className={`d-flex justify-content-between`}>
                        <div>
                            {this.responseHelper.RenderCreateResponse(this.state.response)}
                            {this.state.nameEmpty && <InfoMessage text={'Vyplňte název!'} />}
                            {this.state.codeEmpty && <InfoMessage text={'Vyplňte kód!'} />}
                            {this.state.defaultAndNotActive && <InfoMessage text={'Nelze založit neaktivní výchozí jazyk!'} />}
                        </div>
                        <Button className='col-2' text={"Přidat"} OnClick={() => this.CreateLanguage()} />
                    </div>
                </div>
            </div>
        );
    }

}
