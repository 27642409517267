import React, { Component } from 'react'; 
import { Button } from '@components/shared/Button';
import { Spinner } from '@components/shared/Spinner';
import { InfoMessage } from '@components/shared/InfoMessage';
import axios from "axios";
import AdmResponseHelper from '@model/helper/AdmResponseHelper';
import { SliderOnOff } from '@components/shared/SliderOnOff';
import { SelectTranslation } from '@components/shared/SelectTranslation';

export class EditCareer extends Component {
    static displayName = EditCareer.name;

    constructor(props) {
        super(props);

        this.target = this.props.targetCareer;

        this.state = {
            internalNote: this.target.internalNote,
            internalNoteEmpty: false,

            active: this.target.isActive,

            selectedNameUrlId: this.target.idTranslationNameUrl == null ? 'default' : this.target.idTranslationNameUrl,
            selectedNameId: this.target.idTranslationName == null ? 'default' : this.target.idTranslationName,
            selectedBranchId: this.target.idTranslationBranch == null ? 'default' : this.target.idTranslationBranch,
            selectedLocationId: this.target.idTranslationLocation == null ? 'default' : this.target.idTranslationLocation,
            selectedDescriptionId: this.target.idTranslationDescription == null ? 'default' : this.target.idTranslationDescription,
            selectedAdditionalInformationId: this.target.idTranslationAdditionalInformation == null ? 'default': this.target.idTranslationAdditionalInformation,
            selectedBenefitsId: this.target.idTranslationBenefits == null ? 'default' : this.target.idTranslationBenefits,

            selectedNameUrlEmpty: false,
            selectedNameEmpty: false,
            selectedBranchEmpty: false,
            selectedLocationEmpty: false,
            selectedDescriptionEmpty: false,

            showDeleteConfirmation: false,
            responseEdit: null,
            responseDelete: null,
            loading: false,
        };

        this.responseHelper = new AdmResponseHelper();
        this.loginHeader = {
            headers: {
                'Header-Authority': JSON.stringify({ Token: this.props.loggedAdmin.loginToken })
            }
        };
    }

    componentDidMount() {
        document.body.classList.add('noScroll');
    }

    componentWillUnmount() {
        document.body.classList.remove('noScroll');
    }

    DeleteCareer = () => {

        this.setState((prevState) => ({ ...prevState, loading: true }));

        axios
            .delete(`/api/adm/career/delete/${this.target.id}`, this.loginHeader)
            .then((response) => {
                let data = response.data;
                this.setState({ responseDelete: data.responseCode });

                if (data.responseCode == 200) {
                    this.props.GetCareerList();
                    setTimeout(() => {
                        this.props.HandleCloseForm();
                    }, 200);
                }

            }).catch(() => {
                this.setState((prevState) => ({ ...prevState, responseDelete: 500 }));
            }).finally(() => {
                this.setState((prevState) => ({ ...prevState, loading: false }));
            });
    }

    EditCareer = () => {

        if (!this.ValidateSelects())
            return;

        this.setState((prevState) => ({ ...prevState, loading: true }));

        axios
            .patch("/api/adm/career/edit", {
                LoginData: { Token: this.props.loggedAdmin.loginToken },
                Id: this.target.id,
                InternalNote: this.state.internalNote,
                IsActive: this.state.active,
                IdTranslationNameUrl: this.state.selectedNameUrlId,
                IdTranslationName: this.state.selectedNameId,
                IdTranslationBranch: this.state.selectedBranchId,
                IdTranslationLocation: this.state.selectedLocationId,
                IdTranslationDescription: this.state.selectedDescriptionId,
                IdTranslationAdditionalInformation: this.state.selectedAdditionalInformationId == 'default' ? null : this.state.selectedAdditionalInformationId,
                IdTranslationBenefits: this.state.selectedBenefitsId == 'default' ? null : this.state.selectedBenefitsId
            }, this.loginHeader)
            .then((resp) => {
                let data = resp.data;

                this.setState((prevState) => ({ ...prevState, responseEdit: data.responseCode }));

                if (data.responseCode == 200) {
                    this.props.GetCareerList();
                    setTimeout(() => {
                        this.props.HandleCloseForm();
                    }, 200);
                }
            })
            .catch(() => {
                this.setState((prevState) => ({ ...prevState, responseEdit: 500 }));
            })
            .finally(() => {
                this.setState((prevState) => ({ ...prevState, loading: false }));
            });
    }

    HandleSetInternalNote = (val) => {
        this.setState((prevState) => ({ ...prevState, internalNote: val, response: null, internalNoteEmpty: false }));
    }

    HandleToggleActive = () => {
        this.setState((prevState) => ({ ...prevState, active: !this.state.active, response: null }));
    }

    ValidateSelects = () => {
        let internalNoteEmpty = this.state.internalNote.length <= 0;
        let selectedNameUrlEmpty = this.state.selectedNameUrlId == 'default';
        let selectedNameEmpty = this.state.selectedNameId == 'default';
        let selectedBranchEmpty = this.state.selectedBranchId == 'default';
        let selectedLocationEmpty = this.state.selectedLocationId == 'default';
        let selectedDescriptionEmpty = this.state.selectedDescriptionId == 'default';

        this.setState((prevState) => ({
            ...prevState,
            internalNoteEmpty: internalNoteEmpty,
            selectedNameUrlEmpty: selectedNameUrlEmpty,
            selectedNameEmpty: selectedNameEmpty,
            selectedBranchEmpty: selectedBranchEmpty,
            selectedLocationEmpty: selectedLocationEmpty,
            selectedDescriptionEmpty: selectedDescriptionEmpty,
        }));

        return !(
            internalNoteEmpty ||
            selectedNameUrlEmpty ||
            selectedNameEmpty ||
            selectedBranchEmpty ||
            selectedLocationEmpty ||
            selectedDescriptionEmpty
        );
    }

    HandleSetSelectedName = (val) => {
        this.setState((prevState) => ({ ...prevState, selectedNameId: val, selectedNameEmpty: false }));
    }

    HandleSetSelectedNameUrl = (val) => {
        this.setState((prevState) => ({ ...prevState, selectedNameUrlId: val, selectedNameUrlEmpty: false }));
    }

    HandleSetSelectedBranch = (val) => {
        this.setState((prevState) => ({ ...prevState, selectedBranchId: val, selectedBranchEmpty: false }));
    }

    HandleSetSelectedLocation = (val) => {
        this.setState((prevState) => ({ ...prevState, selectedLocationId: val, selectedLocationEmpty: false }));
    }

    HandleSetSelectedDescription = (val) => {
        this.setState((prevState) => ({ ...prevState, selectedDescriptionId: val, selectedDescriptionEmpty: false }));
    }

    HandleSetSelectedAdditionalInformation = (val) => {
        this.setState((prevState) => ({ ...prevState, selectedAdditionalInformationId: val }));
    }

    HandleSetSelectedBenefits = (val) => {
        this.setState((prevState) => ({ ...prevState, selectedBenefitsId: val }));
    }

    HandleToggleDeleteConfirmation = () => {
        this.setState((prevState) => ({ ...prevState, showDeleteConfirmation: !this.state.showDeleteConfirmation, responseEdit: null }));
    }

    render() {
        return (
            <div className='form border rounded p-3 fitH'>
                <div className='d-flex align-items-center justify-content-between mb-3'>
                    <h2 className='m-0'>Upravit kariéru</h2>
                    <div className='d-flex justify-content-end'>
                        <Button text={<i className="fa-solid fa-xmark"></i>} OnClick={() => this.props.HandleCloseForm()} />
                    </div>
                </div>
                <div className='position-relative'>
                    {this.state.loading &&
                        <Spinner absolute whiteBg />
                    }
                    <div className={`d-flex flex-column mb-3 col-12`}>
                        <label htmlFor='id'>ID <i className="ms-2 fa-solid fa-lock"></i></label>
                        <input type='text' id='id' name='id' className='ps-2 readonly' readOnly value={this.target.id} />
                    </div>
                    <div className='my-2 mb-3'>
                        <SliderOnOff text={'Aktivní'} isChecked={this.state.active} OnChange={() => this.HandleToggleActive()} />
                    </div>
                    <div className='d-flex flex-column'>
                        <div className={`d-flex flex-column mb-3 col-12 ${this.state.internalNoteEmpty && 'inputError'}`}>
                            <label htmlFor='description'>Interní popis</label>
                            <textarea type='text' id='description' name='description' className='ps-2' value={this.state.internalNote} onChange={(e) => this.HandleSetInternalNote(e.target.value)} />
                        </div>
                    </div>
                    {<SelectTranslation withNote text={'Překlad url* (url friendly)'} selectedId={this.state.selectedNameUrlId} OnChange={this.HandleSetSelectedNameUrl} translationList={this.props.translationList} />}
                    {<SelectTranslation withNote text={'Překlad názvu*'} selectedId={this.state.selectedNameId} OnChange={this.HandleSetSelectedName} translationList={this.props.translationList} />}
                    {<SelectTranslation withNote text={'Překlad odvětví*'} selectedId={this.state.selectedBranchId} OnChange={this.HandleSetSelectedBranch} translationList={this.props.translationList} />}
                    {<SelectTranslation withNote text={'Překlad lokace*'} selectedId={this.state.selectedLocationId} OnChange={this.HandleSetSelectedLocation} translationList={this.props.translationList} />}
                    {<SelectTranslation withNote text={'Překlad popisu*'} selectedId={this.state.selectedDescriptionId} OnChange={this.HandleSetSelectedDescription} translationList={this.props.translationList} />}
                    {<SelectTranslation withNote text={'Překlad dodatečných informací'} selectedId={this.state.selectedAdditionalInformationId} OnChange={this.HandleSetSelectedAdditionalInformation} optional translationList={this.props.translationList} />}
                    {<SelectTranslation withNote text={'Překlad benefitů'} selectedId={this.state.selectedBenefitsId} OnChange={this.HandleSetSelectedBenefits} optional translationList={this.props.translationList} />}                  
                    <div className={`d-flex flex-column`}>
                        <div className='d-flex flex-column'>
                            <div className='d-flex justify-content-between col'>
                                <div className='d-flex flex-column flex-sm-row'>
                                    <Button className='col-2' text={this.state.showDeleteConfirmation ? 'Zrušit' : 'Smazat'} OnClick={() => this.HandleToggleDeleteConfirmation()} />
                                    {this.state.showDeleteConfirmation &&
                                        <div className='mt-2 mt-sm-0 ms-sm-1'><Button className='col-2 m-5' text={'Opravdu smazat'} OnClick={() => this.DeleteCareer()} /></div>
                                    }
                                </div>
                                <Button className='col-2' text={"Upravit"} OnClick={() => this.EditCareer()} />
                            </div>
                            <div className='d-flex flex-column mb-2 mt-3'>
                                {this.state.internalNoteEmpty && <InfoMessage text={"Popis není vyplněn!"} />}
                                {this.responseHelper.RenderDeleteResponse(this.state.responseDelete)}
                                {this.responseHelper.RenderEditResponse(this.state.responseEdit)}
                                {
                                    (
                                        this.state.selectedNameUrlEmpty ||
                                        this.state.selectedNameEmpty ||
                                        this.state.selectedBranchEmpty ||
                                        this.state.selectedLocationEmpty ||
                                        this.state.selectedDescriptionEmpty)
                                    && <InfoMessage text={"Vyberte všechny povinné překlady!"} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
