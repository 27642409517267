
import { InfoMessage } from '@components/shared/InfoMessage';

class AdmResponseHelper {

    RenderCascadeDeleteWarning() {
        return <InfoMessage text={"Smazáním tohoto záznamu se smažou veškteré navázené překlady!"} stayVisible />;
    }

    RenderLoginInvalid() {
        return <InfoMessage text={"Neplatné přihlášení."} stayVisible />;
    }

    RenderSomethingWentWrong() {
        return <InfoMessage text={"Vyskytla se nečekaná chyba."} stayVisible />;
    }

    RenderInvalidInput() {
        return <InfoMessage text={"Chyba v zadaných datech."} />;
    }

    RenderDuplicateRecord() {
        return <InfoMessage text={"Záznam již existuje!"} />;
    }

    RenderNotFound() {
        return <InfoMessage text={"Požadovaný záznam nebyl nalezen!"} />;
    }

    RenderDeleteResponse = (responseCode) => {

        switch (responseCode) {
            case 200:
                return <InfoMessage text={"Záznam byl úspěšně smazán!"} isSuccess />;
            case 400:
                return this.RenderInvalidInput();
            case 401:
                return this.RenderLoginInvalid();
            case 403:
                return <InfoMessage text={"Záznam nelze smazat!"} />;
            case 404:
                return this.RenderNotFound();
            case 500:
                return this.RenderSomethingWentWrong();
            default:
                return;
        }
    }

    RenderEditResponse = (responseCode) => {

        switch (responseCode) {
            case 200:
                return <InfoMessage text={"Záznam byl úspěšně upraven!"} isSuccess />;
            case 400:
                return this.RenderInvalidInput();
            case 401:
                return this.RenderLoginInvalid();
            case 403:
                return <InfoMessage text={"Záznam nelze upravit!"} />;
            case 404:
                return this.RenderNotFound();
            case 409:
                return this.RenderDuplicateRecord();
            case 500:
                return this.RenderSomethingWentWrong();
            default:
                return;
        }
    }

    RenderCreateResponse = (responseCode) => {

        switch (responseCode) {
            case 200:
                return <InfoMessage text={"Záznam byl úspěšně založen!"} isSuccess />;
            case 400:
                return this.RenderInvalidInput();
            case 401:
                return this.RenderLoginInvalid();
            case 409:
                return this.RenderDuplicateRecord();
            case 500:
                return this.RenderSomethingWentWrong();
            default:
                return;
        }
    }
}

export default AdmResponseHelper;