
//import axios from "axios";

class AdmHelper {

    GetLocalizationCzechOrOther = (translation, quotes = true, noteAsBackup = true) => {

        if (typeof translation != 'object')
            return "";

        if (!('localizationList' in translation))
            return "";

        if (translation.localizationList.length <= 0)
            return noteAsBackup ? translation.internalNote : "";

        let czech = translation.localizationList.find(l => l.language.code == 'cs');
        if (czech)
            return quotes ? `"${czech.value}"` : czech.value;

        let en = translation.localizationList.find(l => l.language.code == 'en');
        if (en)
            return quotes ? `"${en.value}"` : en.value;

        return quotes ? `"${translation.localizationList[0].value}"` : translation.localizationList[0].value;
    }

    ShortenText = (text, len = 50) => {

        if (text.length > len)
            return text.endsWith('"') ? (text.substring(0, len - 4) + '..."') : (text.substring(0, len - 3) + '...');

        return text;
    }

    GetLocalizationCzechOrOtherShort = (translation, quotes = true, noteAsBackup = true) => {
        return this.ShortenText(this.GetLocalizationCzechOrOther(translation, quotes, noteAsBackup));
    }
}

export default AdmHelper;