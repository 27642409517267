import React, { Component } from 'react'; 
import { Button } from '@components/shared/Button';
import { Spinner } from '@components/shared/Spinner';
import { InfoMessage } from '@components/shared/InfoMessage';
import axios from "axios";
import AdmResponseHelper from '@model/helper/AdmResponseHelper';
import { SliderOnOff } from '@components/shared/SliderOnOff';
import { ToolTip } from '@components/shared/ToolTip';

export class EditTranslation extends Component {
    static displayName = EditTranslation.name;

    constructor(props) {
        super(props);

        let availableLanguages = this.props.languageList.filter(l => !this.props.targetTranslation.localizationList.some(t => t.language.code == l.code))
        let selectedLanguage = availableLanguages.length > 0 ? availableLanguages[0] : null;

        let localizationList = [];
        for (let i in this.props.targetTranslation.localizationList) {
            localizationList.push({
                IdLanguage: this.props.languageList.find(l => l.code == this.props.targetTranslation.localizationList[i].language.code).id,
                Value: this.props.targetTranslation.localizationList[i].value
            })
        }

        this.state = {
            key: this.props.targetTranslation.key,
            keyEmpty: false,

            internalNote: this.props.targetTranslation.internalNote,

            page: this.props.targetTranslation.page.id,

            responseEdit: null,
            responseDelete: null,
            loading: false,

            localizationList: localizationList,

            availableLanguages: availableLanguages,
            selectedLanguage: selectedLanguage,

            isActive: this.props.targetTranslation.isActive,
            isCached: this.props.targetTranslation.isCached,
            editable: this.props.targetTranslation.editable,

            showDeleteConfirmation: false,
            nameConventionError: false,
        };

        this.responseHelper = new AdmResponseHelper();
        this.loginHeader = {
            headers: {
                'Header-Authority': JSON.stringify({ Token: this.props.loggedAdmin.loginToken })
            }
        };
    }

    componentDidMount() {
        document.body.classList.add('noScroll');
    }

    componentWillUnmount() {
        document.body.classList.remove('noScroll');
    }

    HandleSetKey = (val) => {
        this.HandleClearResponses();
        this.setState((prevState) => ({ ...prevState, key: val }));
    }

    HandleSetInternalNote = (val) => {
        this.HandleClearResponses();
        this.setState((prevState) => ({ ...prevState, internalNote: val }));
    }

    HandleSetPage = (val) => {
        this.HandleClearResponses();
        this.setState((prevState) => ({ ...prevState, page: val }));
    }

    HandleSelectLanguage = (id) => {
        let lang = this.props.languageList.find(l => l.id == id);
        this.HandleClearResponses();
        this.setState((prevState) => ({ ...prevState, selectedLanguage: lang }));
    }

    HandleClearResponses = () => {
        this.setState((prevState) => ({
            ...prevState,
            responseEdit: null,
            keyEmpty: false,
            responseDelete: false,
            nameConventionError: false

        }));
    }

    HandleAddNewLocalization = (id) => {

        this.HandleClearResponses();

        let localizationList = this.state.localizationList;
        localizationList.push({
            IdLanguage: id,
            Value: ""
        });

        let availableLanguages = this.props.languageList.filter(l => !localizationList.some(t => t.IdLanguage === l.id));
        let selectedLanguage = availableLanguages.length > 0 ? availableLanguages[0] : null;
        this.setState((prevState) => ({ ...prevState, localizationList: localizationList, availableLanguages: availableLanguages, selectedLanguage: selectedLanguage, response: null }));
    }

    HandleRemoveLocalization = (id) => {

        this.HandleClearResponses();

        let localizationList = this.state.localizationList;
        localizationList = localizationList.filter(t => t.IdLanguage != id);

        let availableLanguages = this.props.languageList.filter(l => !localizationList.some(t => t.IdLanguage == l.id));
        let selectedLanguage = availableLanguages.length > 0 ? availableLanguages[0] : null;

        this.setState((prevState) => ({ ...prevState, localizationList: localizationList, availableLanguages: availableLanguages, selectedLanguage: selectedLanguage, response: null }));
    }

    HandleChangeLanguageTranslationValue = (id, val) => {

        this.HandleClearResponses();

        let localizationList = this.state.localizationList;
        let translation = localizationList.find(l => l.IdLanguage == id);
        translation.Value = val;

        this.setState((prevState) => ({ ...prevState, localizationList: localizationList, response: null }));
    }

    HandleToggleActive = () => {
        this.HandleClearResponses();
        this.setState((prevState) => ({ ...prevState, isActive: !this.state.isActive, response: null }));
    }

    HandleToggleCached = () => {
        this.HandleClearResponses();
        this.setState((prevState) => ({ ...prevState, isCached: !this.state.isCached, response: null }));
    }

    HandleClickLanguageImage = (e) => {
        let select = document.getElementById('language');
        select.focus();
    }

    DeleteTranslations = () => {
 
        this.setState((prevState) => ({ ...prevState, loading: true }));

        axios
            .delete(`/api/adm/translation/delete/${this.props.targetTranslation.id}`, this.loginHeader)
            .then((resp) => {
                let data = resp.data;

                this.setState((prevState) => ({
                    ...prevState,
                    responseDelete: data.responseCode
                }));

                if (data.responseCode === 200) {
                    setTimeout(() => {
                        this.props.HandleCloseForm();
                    }, 600);
                    this.props.GetTranslationList();
                }
            })
            .catch(() => {
                this.setState((prevState) => ({ ...prevState, responseDelete: 500 }));
            })
            .finally(() => {
                this.setState((prevState) => ({ ...prevState, loading: false }));
            });
    }

    EditTranslation = () => {

        let keyEmpty = this.state.key.length <= 0;

        let targetPage = this.props.pageList.find(p => p.id == this.state.page);
        let nameConventionError = !this.state.key.startsWith(targetPage.translationPrefix);
        this.setState((prevState) => ({ ...prevState, loading: !(keyEmpty || nameConventionError), keyEmpty: keyEmpty, nameConventionError: nameConventionError }));
        if (keyEmpty || nameConventionError)
            return;

        axios
            .patch("/api/adm/translation/edit", {
                Id: this.props.targetTranslation.id,
                Key: this.state.key,
                IdPage: parseInt(this.state.page),
                LocalizationList: this.state.localizationList,
                IsActive: this.state.isActive,
                IsCached: this.state.isCached,
                InternalNote: this.state.internalNote
            }, this.loginHeader)
            .then((resp) => {
                let data = resp.data;

                this.setState((prevState) => ({
                    ...prevState,
                    responseEdit: data.responseCode
                }));

                if (data.responseCode === 200) {
                    setTimeout(() => {
                        this.props.HandleCloseForm();
                    }, 600);
                    this.props.GetTranslationList();
                }

            })
            .catch(() => {
                this.setState((prevState) => ({ ...prevState, responseEdit: 400 }));
            })
            .finally(() => {
                this.setState((prevState) => ({ ...prevState, loading: false }));
            });
    }

    HandleToggleDeleteConfirmation = () => {
        this.HandleClearResponses();
        this.setState((prevState) => ({ ...prevState, showDeleteConfirmation: !this.state.showDeleteConfirmation }));
    }

    RenderNameConventionError = () => {

        let targetPage = this.props.pageList.find(p => p.id == this.state.page);
        return (
            <InfoMessage text={`Nesprávný prefix klíče překladu! Požadovaný prefix: \"${targetPage.translationPrefix}\"`} stayVisible />
        );
    }

    IsPageCareer = () => {
        let currentPage = this.props.pageList.find(p => p.id == this.state.page);
        return currentPage?.name == 'career';
    }

    render() {
        return (
            <div className='form border rounded p-3 fitH'>
                <div className='d-flex align-items-center justify-content-between mb-3'>
                    <div className='d-flex align-items-center'>
                        <h2 className='m-0'>Upravit překlad</h2>
                        {!this.state.editable &&
                            <h4 className='m-0 ms-2 redText opacity-75'> - Neupravitelné</h4>
                        }
                    </div>
                    <div className='d-flex justify-content-end'>
                        <Button text={<i className="fa-solid fa-xmark"></i>} OnClick={() => this.props.HandleCloseForm()} />
                    </div>
                </div>
                <div className='position-relative'>
                    {this.state.loading &&
                        <Spinner absolute whiteBg />
                    }
                    <div className='d-flex align-items-center mb-2 flex-column col-12 flex-md-row'>
                        <div className={`d-flex flex-column col-12 border-md-end pe-2 col-md-4`}>
                            <label htmlFor='id'>ID <i className="ms-1 fa-solid fa-lock"></i></label>
                            <input type='text' id='id' className='ps-2 readonly' readOnly value={this.props.targetTranslation.id} />
                        </div>
                        <div className='d-flex justify-content-between col-12 col-md-8 mt-3 '>
                            <div className='col d-flex justify-content-center align-items-center'>
                                <SliderOnOff text={'Aktivní'} isChecked={this.state.isActive} OnChange={() => this.HandleToggleActive()} readOnly={!this.state.editable} />
                            </div>
                            {this.IsPageCareer() ?
                                <div className='col d-flex justify-content-center'>
                                    <SliderOnOff text={'Překlad kariéry'} col isChecked={!this.state.isCached} OnChange={() => this.HandleToggleCached()} readOnly={!this.state.editable} />
                                    <div className='ms-1 w-auto'>
                                        <ToolTip text={'Překlad vázaný na konkrétní kariéru. Např. překlad názvu kariéry, popisu atd. Nenačítá se s normálními překlady, protože se z db načítá rovnou přeložený při požadavku o konkrétní kariéru. Tento překlad není určen ke standardnímu použití na stránce.'} />
                                    </div>
                                </div>
                                :
                                <div className='col'></div>
                            }
                        </div>
                    </div>
                    <div className='d-flex flex-column flex-md-row align-items-md-end justify-content-md-between'>
                        <div className={`d-flex flex-column mb-3 mb-md-0 col-12 col-md-7 ${(this.state.keyEmpty || this.state.nameConventionError) && 'inputError'}`}>
                            <label htmlFor='key'>Key</label>
                            <input type='text' id='key' className={`ps-2`} value={this.state.key} onChange={(e) => this.HandleSetKey(e.target.value)} disabled={!this.state.editable} />
                        </div>
                        <div className={`d-flex flex-column mb-3 mb-md-0 col-12 col-md-4`}>
                            <label htmlFor='page'>Stránka</label>
                            <select type='text' id='page' className='ps-2' value={this.state.page} onChange={(e) => this.HandleSetPage(e.target.value)} disabled={!this.state.editable}>
                                {this.props.pageList.map(page => {
                                    return (
                                        <option key={page.id} value={page.id}>{`${page.name} (\"${page.translationPrefix}\") ${!page.isActive ? '(neaktivní)' : ''}`}</option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <div className={`d-flex flex-column mb-3 col-12`}>
                        <label htmlFor='note'>Interní popis</label>
                        <textarea type='text' id='note' className='ps-2' value={this.state.internalNote} onChange={(e) => this.HandleSetInternalNote(e.target.value)} disabled={!this.state.editable} />
                    </div>
                    {this.state.availableLanguages.length > 0 &&
                        <div className='d-flex align-items-center col pe-2'>
                            <div className='d-flex w-100 flex-column flex-sm-row align-items-sm-end '>
                                <div className={`me-3 position-relative d-flex flex-column col-12 col-sm-auto mb-2 mb-sm-0`}>
                                    <label htmlFor='language'>Přidat lokalizaci</label>
                                    <select type='text' id='language' className='p-1 ps-2 pe-5' value={this.state.selectedLanguage.id} onChange={(e) => this.HandleSelectLanguage(e.target.value)}>
                                        {this.state.availableLanguages.map(language => {
                                            return (
                                                 <option key={language.id} value={language.id}>{language.code.toUpperCase()} - {language.name} {language.isDefault && '(výchozí)'} {!language.isActive && '(neaktivní)'}</option>
                                            );
                                        })}
                                    </select>
                                    {this.state.selectedLanguage.imgPath.length > 0 &&
                                        <img className='translationLangNew position-absolute' src={`/files/images/${this.state.selectedLanguage.imgPath}`} alt={`Vlajka jazyka ${this.state.selectedLanguage.name}`} onClick={(e) => this.HandleClickLanguageImage(e)} />    
                                    }
                                </div>
                                <Button text={'Přidat'} OnClick={() => this.HandleAddNewLocalization(this.state.selectedLanguage.id)} small />
                            </div>
                        </div>
                    }
                    <div>
                        {this.state.localizationList.map(loc => {

                            let lang = this.props.languageList.find(l => l.id == loc.IdLanguage);

                            return (
                                <div className='translation d-flex flex-column border-top p-2 px-3 my-1' key={lang.id}>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className={`position-relative me-3 d-flex flex-column mb-3 col`}>
                                            <label htmlFor='lang'>Jazyk <i className="ms-2 fa-solid fa-lock"></i></label>
                                            <input type='text' id='lang' className='ps-2 readonly' readOnly value={lang.name} />
                                            <img className='translationLangNew position-absolute' src={`/files/images/${lang.imgPath}`} alt={`Vlajka jazyka ${lang.name}`} />
                                        </div>
                                        <Button text={'Odstranit překlad'} OnClick={() => this.HandleRemoveLocalization(lang.id)} small disabled={!this.state.editable} />
                                    </div>
                                    <div className={`d-flex flex-column mb-1 col-12`}>
                                        <label htmlFor='value'>Překlad</label>
                                        <textarea type='text' id='value' className='ps-2' value={loc.Value} onChange={(e) => this.HandleChangeLanguageTranslationValue(lang.id, e.target.value)} disabled={!this.state.editable} />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className='d-flex justify-content-between pt-3'>
                        <div className='d-flex flex-column'>
                            <div className='d-flex'>
                                <Button className='col-2' text={this.state.showDeleteConfirmation ? 'Zrušit' : 'Smazat vše'} OnClick={() => this.HandleToggleDeleteConfirmation()} disabled={!this.state.editable} />
                                {this.state.showDeleteConfirmation &&
                                    <div className='ms-1'><Button className='col-2 m-5' text={'Opravdu smazat'} OnClick={() => this.DeleteTranslations()} /></div>
                                }
                            </div>
                        </div>
                        <div className='d-flex flex-column align-items-end'>
                            <div className='d-flex'>
                                <Button className='col-2' text={"Upravit"} OnClick={() => this.EditTranslation()} disabled={!this.state.editable} />
                            </div>
                        </div>
                    </div>
                    <div className={`d-flex flex-column mt-3 responses`}>
                        {this.state.key != this.props.targetTranslation.key && <InfoMessage text={"Pokud je překlad staticky použitý na stránce, přestane po změně klíče fungovat!"} stayVisible />}
                        {this.responseHelper.RenderEditResponse(this.state.responseEdit)}
                        {this.responseHelper.RenderDeleteResponse(this.state.responseDelete)}
                        {this.state.nameConventionError && this.RenderNameConventionError()}
                        {this.state.keyEmpty && <InfoMessage text={'Vyplňte klíč!'} />}
                    </div>
                </div>
            </div>
        );
    }

}
