import React, { Component } from 'react';
import '@styles/shared/infoMessage.scss';

export class InfoMessage extends Component {
    static displayName = InfoMessage.name;

    constructor(props) {
        super(props);

        this.state = {
            visible: true,
        };

        this.hideTimer = null;

        this.timeoutTime = 4000;
    }

    componentDidMount() {

        if (this.props.stayVisible)
            return;

        this.startHideTimer();
    }

    componentWillUnmount() {
        clearTimeout(this.hideTimer);
    }

    componentDidUpdate(prevProps) {

        if (this.props.stayVisible)
            return;

        // Reset the visibility and start the hide timer when receiving new props
        if (this.props !== prevProps) {
            this.setState({ visible: true }, () => {
                this.startHideTimer();
            });
        }
    }

    startHideTimer = () => {

        if (this.props.stayVisible)
            return;

        // Clear the previous hide timer before starting a new one
        clearTimeout(this.hideTimer);

        this.hideTimer = setTimeout(() => {
            this.setState({ visible: false });
        }, this.timeoutTime);
    };

    render() {
        const { visible } = this.state;

        return (
            <span className={`${!visible && 'd-none'} d-flex my-1 infoMessage align-items-center ${this.props.maxW ? 'maxW text-wrap' : ''}`}>
                <div className={`colorCircle ${this.props.isSuccess ? 'green' : 'red'} me-1`}></div>
                <span className=''>{this.props.text}</span>
            </span>
        );
    }
}
