import React, { Component } from 'react';
import '@styles/adm/careers.scss';
import { InfoMessage } from '@components/shared/InfoMessage';
import { Spinner } from '@components/shared/Spinner';
import { Button } from '@components/shared/Button';
import { NewCareer } from './components/NewCareer';
import { EditCareer } from './components/EditCareer';
import axios from "axios";
import AdmResponseHelper from '@model/helper/AdmResponseHelper';
import { BreadCrumbs } from '@components/shared/BreadCrumbs';
import ShowMore from '@components/shared/ShowMore';

export class Careers extends Component {
    static displayName = Careers.name;

    constructor(props) {
        super(props);
        this.state = {

            careerList: [],
            response: null,

            newFormOpen: false,
            editFormOpen: false,
            editCareerData: null,

            loading: true,

            languageList: [],
            translationList: [],
            translationListEmpty: false
        }

        // forcererender
        this.oldList = [];

        this.responseHelper = new AdmResponseHelper();
        this.loginHeader = {
            headers: {
                'Header-Authority': JSON.stringify({ Token: this.props.loggedAdmin.loginToken })
            }
        };

        this.breadCrumbPath = [
            { text: "Správa kariér", link: null }
        ];
    }

    componentDidUpdate() {

        if (this.state.careerList != this.oldList) {
            this.oldList = this.state.careerList;

            if (this.state.editCareerData == null)
                return;

            this.HandleOpenEditForm(this.state.editCareerData.id);
        }
    }

    componentDidMount() {
        this.GetCareerTranslationList();
    }

    GetCareerTranslationList = async () => {

        axios
            .get("/api/adm/page/list", this.loginHeader)
            .then((response) => {
                let data = response.data;
                let careerPageId = data.pageList.find(p => p.name == 'career');
                if (careerPageId)
                    careerPageId = careerPageId.id;

                return axios
                    .post("/api/adm/translation/list", {
                        IdPage: careerPageId ?? null,
                        PaginationCount: null,
                        IsCached: false
                    }, this.loginHeader)
                    .then((response) => {
                        let data = response.data;

                        this.setState((prevState) => ({ ...prevState, response: data.responseCode, translationList: data.translationList, translationListEmpty: data.translationList.length <= 0 }));

                        if (data.translationList.length <= 0)
                            return;

                        return this.GetCareerList();
                    }).catch(() => {
                        this.setState((prevState) => ({ ...prevState, response: 500 }));
                    });

            }).catch(() => {
                this.setState((prevState) => ({ ...prevState, response: 500 }));
            }).finally(() => {
                this.setState((prevState) => ({ ...prevState, loading: false }));
            });
    }

    GetCareerListSetIsComplete = (careerList) => {
        for (let career in careerList) {
            let c = careerList[career];

            let name = this.state.translationList.find(t => t.id == c.idTranslationName)
            let nameCompleted = (name != null) && name.isComplete;

            let branch = this.state.translationList.find(t => t.id == c.idTranslationBranch)
            let branchCompleted = (branch != null) && branch.isComplete;

            let location = this.state.translationList.find(t => t.id == c.idTranslationLocation)
            let locationCompleted = (location != null) && location.isComplete;

            let description = this.state.translationList.find(t => t.id == c.idTranslationDescription)
            let descriptionCompleted = (description != null) && description.isComplete;

            c.isComplete = (nameCompleted && branchCompleted && locationCompleted && descriptionCompleted);
        }

        return careerList;
    }

    GetCareerList = () => {

        this.setState((prevState) => ({ ...prevState, loading: true }));

        return axios
            .get("/api/adm/career/list", this.loginHeader)
            .then((response) => {
                let data = response.data;

                if (data.responseCode != 200)
                    return;

                data.careerList = this.GetCareerListSetIsComplete(data.careerList);

                this.setState((prevState) => ({ ...prevState, response: data.responseCode, careerList: data.careerList }));
            }).catch(() => {
                this.setState((prevState) => ({ ...prevState, response: 500 }));
            }).finally(() => {
                this.setState((prevState) => ({ ...prevState, loading: false }));
            });
    }

    RenderResponse = () => {

        switch (this.state.response) {
            case 400:
                return <InfoMessage text={"Neplatné a."} stayVisible />;
            case 401:
                return this.responseHelper.RenderLoginInvalid();
            case 500:
                return this.responseHelper.RenderSomethingWentWrong();
            default:
                return;
        }
    }

    HandleOpenNewForm = () => {
        let canOpen = !this.state.translationListEmpty;
        this.setState({ ...this.state, newFormOpen: canOpen, editFormOpen: false });
    }

    HandleCloseForm = () => {
        this.setState({ ...this.state, newFormOpen: false, editFormOpen: false });
    }

    HandleOpenEditForm = (id) => {

        if (id == null)
            return;

        let targetCareer = this.state.careerList.find(c => c.id === id);

        this.setState({ ...this.state, editFormOpen: true, newFormOpen: false, editCareerData: targetCareer });
    }

    HandleCloseEditForm = () => {
        this.setState({ ...this.state, editFormOpen: false, editCareerData: null });
    }

    RenderForms = () => {

        if (this.state.editFormOpen && this.state.editCareerData == null)
            return

        return (
            (this.state.newFormOpen || this.state.editFormOpen) &&
            <div className='position-fixed h-100 formGlass d-flex justify-content-center pt-5'>
                {this.state.newFormOpen ?
                    <NewCareer HandleCloseForm={() => this.HandleCloseForm()} loggedAdmin={this.props.loggedAdmin} translationList={this.state.translationList} GetCareerList={() => this.GetCareerList()} />
                    :
                    this.state.editFormOpen &&
                    <EditCareer HandleCloseForm={() => this.HandleCloseForm()} loggedAdmin={this.props.loggedAdmin} translationList={this.state.translationList} GetCareerList={() => this.GetCareerList()} targetCareer={this.state.editCareerData} />
                }
            </div>
        );
    }

    RenderCareerList = () => {

        return (
            this.state.careerList.map((career) => {

                let danger = career.isActive && !career.isComplete;

                return <div className={`position-relative col-12 d-flex border-top border-bottom align-items-center py-2 ${danger && 'red'}`} role='button' key={career.id} onClick={() => this.HandleOpenEditForm(career.id)}>
                    <div className='col-1 px-1'>{career.id}</div>
                    <div className='col-2 px-1 fw-bold'>{career.nameLocalization}</div>
                    <div className='col-2 px-1'>{career.isActive ? 
                        <div className='d-flex align-items-center'>
                            Ano
                            <div className={`colorCircle green ms-1`}></div>
                        </div>
                        : 
                        <div className='d-flex align-items-center'>
                            Ne
                            <div className={`colorCircle grayDarker ms-1`}></div>
                        </div>
                    }</div>
                    <div className='col-2 px-1 position-relative'>
                        
                        {career.isComplete ? 
                            <div className='d-flex align-items-center'>
                                Ano
                                <div className={`colorCircle green ms-1`}></div>
                            </div>
                            : 
                            <div className='d-flex align-items-center'>
                                Ne
                                {career.isActive ? <div className={`colorCircle red ms-1`}></div> : <div className={`colorCircle grayDarker ms-1`}></div>}
                            </div>
                        }
                    </div>
                    <div className='col-5 px-1'>{career.internalNote}</div>
                    {danger && <i className="fa-solid fa-exclamation position-absolute danger"></i>}
                </div>
            })
        );
    }

    render() {
        return (
            <div id='admCareers' className={`position-relative ${this.props.noHeight && 'noHeight'} pb-5 border-bottom`}>
                <BreadCrumbs
                    adm
                    path={this.breadCrumbPath}
                />
                <div className='mt-3 mb-2 d-flex flex-column flex-sm-row align-items-sm-center'>
                    <h1 className='m-0'>Správa kariér</h1>
                    <div className='d-flex justify-content-end ms-sm-4'>
                        <Button text={" + Přidat"} OnClick={() => this.HandleOpenNewForm()} />
                    </div>
                </div>
                <ShowMore label={"Více informací"} text={
                    <ul className='mt-2'>
                        <li>Na veřejné stránce se zobrazují pouze aktivní kariéry.</li>
                        <li>Kompletní = přeložené do všech aktivních jazyků.</li>
                        <li>Každá kariéra musí mít rozdílný překlad URL!</li>
                    </ul>
                } />  
                {this.state.translationListEmpty &&
                    <InfoMessage text={"Nejdříve přiřadtě překlady ke stránce career!"} stayVisible />
                }
                {this.RenderResponse()}
                <div className='mt-4'>
                    <div className='position-relative'>
                        <div className='careerList'>
                            <div className='col-12 d-flex mt-3'>
                                <div className='col-1 px-1 fw-bold'>ID</div>
                                <div className='col-2 px-1 fw-bold'>Název</div>
                                <div className='col-2 px-1 fw-bold'>Aktivní</div>
                                <div className='col-2 px-1 fw-bold'>Kompletní</div>
                                <div className='col-5 px-1 fw-bold'>Interní popis</div>
                            </div>
                            {this.state.loading ?
                                <div className='m-4'><Spinner dark /></div>
                                :
                                <div className='records col-12 mb-4'>
                                    {this.RenderCareerList()}
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {this.RenderForms()}
            </div>
        );
    }
}
