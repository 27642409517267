import React, { Component } from 'react';

export class Animation extends Component {
    static displayName = Animation.name;

    constructor(props) {
        super(props);

        this.mobileBreakpoint = 992;
        this.state = {
            isMobile: this.IsMobile(),
            shown: this.props.forceVisible ?? false,
            ref: React.createRef(),
        };

        this.product = this.props.product;
        this.screenHeightThresholdMobile = 0.84;
        this.screenHeightThresholdPc = 0.8;

        this.debounceTimeout = null;
        this.debounceDelay = 60;
    }

    componentDidMount() {
        window.addEventListener('resize', this.HandleResize);
        window.addEventListener('scroll', this.HandleScroll);

        if (!this.props.forceVisible) this.HandleScroll();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.HandleResize);
        window.removeEventListener('scroll', this.HandleScroll);

        if (this.debounceTimeout) {
            clearTimeout(this.debounceTimeout); // Clear any pending debounce on unmount
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.shown && !prevState.shown && this.props.once) {
            window.removeEventListener('scroll', this.HandleScroll);
        }

        if (this.state.ref.current != null && prevState.ref.current == null) {
            this.HandleScroll();
        }
    }

    HandleResize = () => {
        const isMobile = this.IsMobile();
        if (this.state.isMobile !== isMobile) {
            this.setState((prevState) => ({ ...prevState, isMobile: isMobile }));
        }
    };

    HandleScroll = () => {

        this.SetShowState();

        // Debounce logic: clear previous timeout and set a new one
        if (this.debounceTimeout) {
            clearTimeout(this.debounceTimeout);
        }

        this.debounceTimeout = setTimeout(() => {
            //console.log("Call HandleResize one last time after the debounce delay")
            this.SetShowState(); // Call HandleResize one last time after the debounce delay
        }, this.debounceDelay);
    };

    SetShowState = () => {
        const currentlyShown = this.ShowState();
        this.setState((prevState) => ({ ...prevState, shown: currentlyShown }));
    }

    ShowState = () => {
        if (this.state.ref == null || this.state.ref.current == null) return false;

        return (
            this.state.ref.current.getBoundingClientRect().top <
            window.screen.height *
            (this.state.isMobile ? this.screenHeightThresholdMobile : this.screenHeightThresholdPc)
        );
    };

    IsMobile = () => {
        return window.innerWidth < this.mobileBreakpoint;
    };

    render() {
        return (
            <div className='animation col' ref={this.props.customRef}>
                <div className='d-flex col h-100' ref={this.state.ref}>
                    <div
                        className={`col animationOpacity ${this.state.shown ? `${this.props.in}` : `${this.props.out ?? ''}`
                            }`}
                    >
                        {this.props.component}
                    </div>
                </div>
            </div>
        );
    }
}
