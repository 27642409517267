import React, { Component } from 'react';
import '@styles/shared/button.scss';

export class Button extends Component {
    static displayName = Button.name;

    constructor (props) {
        super(props);
    }

    EmptyFunction = () => {
        // This function does nothing
    }

    DownloadFile = (file) => {
        let path = `/files/documents/${file}`;
        const a = document.createElement('a');
        a.setAttribute('download', file);
        a.setAttribute('href', path);
        a.click();
    };

    render() {

        if (this.props.downloadFile)
            return (
                <a href={`/files/documents/${this.props.downloadFile}`} target='_blank' rel='noreferrer'>
                    <button className={`p-0 button ${this.props.small && 'small'} ${this.props.inverted && 'inverted'} ${this.props.disabled ? 'disabled' : ''} ${(this.props.image || this.props.iconClass) && 'withImgOrIcon'}`}>
                        {this.props.image &&
                            <img src={`/files/images/${this.props.image}`} alt='' className='position-absolute' />
                        }
                        {this.props.iconClass &&
                            <i className={`icon position-absolute ${this.props.iconClass}`}></i>
                        }
                        <div className={`d-flex align-items-center text-nowrap`}>
                            {this.props.text}
                        </div>
                    </button>
                </a>
            );

        let onClick = (this.props.disabled || !this.props.OnClick) ? () => this.EmptyFunction() : () => this.props.OnClick();
        return <div className='position-relative'>
            <button onClick={onClick} className={`p-0 button ${this.props.small && 'small'} ${this.props.inverted && 'inverted'} ${this.props.disabled ? 'disabled' : ''} ${(this.props.image || this.props.iconClass) && 'withImgOrIcon'}`}>
                {this.props.image &&
                    <img src={`/files/images/${this.props.image}`} alt='' className='position-absolute' />
                }
                {this.props.iconClass &&
                    <i className={`icon position-absolute ${this.props.iconClass}`}></i>
                }
                <div className={`d-flex align-items-center text-nowrap`}>
                    <div className='text'>
                        {this.props.text}
                    </div>
                    {this.props.arrow &&
                        <i className={`arrow fa-solid fa-chevron-right d-flex`}></i>
                    }
                </div>
            </button>
        </div>
    }
}
