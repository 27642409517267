
import BaseResponse from '@model/response/BaseResponse';

class SlideShowListResponse extends BaseResponse {

    constructor(slideShowList, responseCode) {

        super(responseCode);
        this.slideShowList = slideShowList;
    }
    
}

export default SlideShowListResponse;