import React, { Component } from 'react';
import '@styles/adm/languages.scss';
import { Spinner } from '@components/shared/Spinner';
import { Button } from '@components/shared/Button';
import { NewLanguage } from './components/NewLanguage';
import { EditLanguage } from './components/EditLanguage';
import axios from "axios";
import AdmResponseHelper from '@model/helper/AdmResponseHelper';
import { BreadCrumbs } from '@components/shared/BreadCrumbs';
import ShowMore from '@components/shared/ShowMore';

export class Languages extends Component {
    static displayName = Languages.name;

    constructor(props) {
        super(props);
        this.state = {

            languageList: [],
            response: null,

            newFormOpen: false,
            editFormOpen: false,
            editLanguageId: null,

            loading: true,
        }

        this.responseHelper = new AdmResponseHelper();
        this.loginHeader = {
            headers: {
                'Header-Authority': JSON.stringify({ Token: this.props.loggedAdmin.loginToken })
            }
        };

        this.breadCrumbPath = [
            { text: "Správa jazyků", link: null }
        ];
    }

    componentDidMount() {
        this.GetLanguageList();
    }

    GetLanguageList = () => {

        this.setState((prevState) => ({ ...prevState, loading: true }));

        return axios
            .get("/api/adm/language/list", this.loginHeader)
            .then((response) => {
                let data = response.data;
                this.setState((prevState) => ({ ...prevState, response: data.responseCode, languageList: data.languageList }));

                if (data.responseCode != 200)
                    return;

                if (this.state.editFormOpen && this.state.editLanguageId != null)
                    this.HandleOpenEditForm(this.state.editLanguageId);

            }).catch(() => {
                this.setState((prevState) => ({ ...prevState, response: 500 }));
            }).finally(() => {
                this.setState((prevState) => ({ ...prevState, loading: false }));
            });
    }

    RenderResponse = () => {

        switch (this.state.response) {
            case 400:
            case 401:
                return this.responseHelper.RenderLoginInvalid();
            case 500:
                return this.responseHelper.RenderSomethingWentWrong();
            default:
                return;
        }
    }

    HandleOpenNewForm = () => {
        this.setState((prevState) => ({ ...prevState, newFormOpen: true, editFormOpen: false }));
    }

    HandleCloseNewForm = () => {
        this.setState((prevState) => ({ ...prevState, newFormOpen: false }));
    }

    HandleOpenEditForm = (id) => {
        this.setState((prevState) => ({ ...prevState, editFormOpen: true, newFormOpen: false, editLanguageId: id }));
    }

    HandleCloseEditForm = () => {
        this.setState((prevState) => ({ ...prevState, editFormOpen: false }));
    }

    RenderForms = () => {

        if (this.state.editFormOpen && this.state.editLanguageId == null)
            return

        return (
            (this.state.newFormOpen || this.state.editFormOpen) &&
            <div className='position-fixed h-100 formGlass d-flex justify-content-center pt-5'>
                {this.state.newFormOpen &&
                    <NewLanguage HandleCloseForm={() => this.HandleCloseNewForm()} loggedAdmin={this.props.loggedAdmin} GetLanguageList={() => this.GetLanguageList()} />
                }
                {this.state.editFormOpen &&
                    <EditLanguage HandleCloseForm={() => this.HandleCloseEditForm()} loggedAdmin={this.props.loggedAdmin} languageList={this.state.languageList} targetId={this.state.editLanguageId} GetLanguageList={() => this.GetLanguageList()} />
                }
            </div>
        );
    }

    RenderLanguageList = () => {

        return (
            this.state.languageList.map((language) => {

                return <div className={`languages position-relative col-12 d-flex border-top border-bottom align-items-center py-2`} role='button' key={language.id} onClick={() => this.HandleOpenEditForm(language.id)}>
                    <div className='col-2 px-1'>{language.id}</div>
                    <div className='col-2 px-1'>{language.name}</div>
                    <div className='col-2 px-1 text-uppercase'>{language.code}</div>
                    <div className='col-2 px-1 position-relative'>
                        {language.isActive ?
                            <div className='d-flex align-items-center'>
                                Ano
                                <div className={`colorCircle green ms-1`}></div>
                            </div>
                            :
                            <div className='d-flex align-items-center'>
                                Ne
                                <div className={`colorCircle grayDarker ms-1`}></div>
                            </div>
                        }
                    </div>
                    <div className='col-2 px-1 position-relative'>
                        {language.isDefault ?
                            <div className='d-flex align-items-center'>
                                Ano
                                <div className={`colorCircle green ms-1`}></div>
                            </div>
                            :
                            'Ne'}
                    </div>
                    <div className='col-2 px-1'>
                        <img src={'/files/images/' + language.imgPath} alt=''/>
                    </div>
                </div>
            })
        );
    }

    render() {
        return (
            <div id='admLanguages' className='position-relative'>
                <BreadCrumbs
                    adm
                    path={this.breadCrumbPath}
                />
                <div className='mt-3 mb-2 d-flex flex-column flex-sm-row align-items-sm-center'>
                    <h1 className='m-0'>Správa jazyků</h1>
                    <div className='d-flex justify-content-end ms-sm-4'>
                        <Button text={" + Přidat"} OnClick={() => this.HandleOpenNewForm()} />
                    </div>
                </div>
                <ShowMore label={"Zobrazit více"} text={
                    <ul className=''>
                        <li>Neaktivní jazyk se ve veřejné části nezobrazuje a nelze do něj stránku přeložit.</li>
                        <li>Výchozí jazyk = jazyk ve kterém se stránka načte, pokud se uživatelův preferovaný jazyk nepodaří načíst z cache, ani jeho prohlížeče.</li>
                    </ul>
                } />  
                {this.RenderResponse()}
                <div className='mt-4'>
                    <div className='position-relative'>
                        {this.state.loading ?
                            <Spinner dark />
                            :
                            <div className='languageList'>
                                <div className='col-12 d-flex mt-3'>
                                    <div className='col-2 px-1 fw-bold'>ID</div>
                                    <div className='col-2 px-1 fw-bold'>Název</div>
                                    <div className='col-2 px-1 fw-bold'>Kód</div>
                                    <div className='col-2 px-1 fw-bold'>Aktivní</div>
                                    <div className='col-2 px-1 fw-bold'>Výchozí</div>
                                    <div className='col-2 px-1 fw-bold'>Obrázek</div>
                                </div>
                                <div className='records col-12 mb-4'>
                                    {this.RenderLanguageList()}
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {this.RenderForms()}
            </div>
        );
    }
}
