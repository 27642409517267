import React, { Component } from 'react';
import { ContactForm } from './ContactForm'
import '@styles/public/home/contact.scss'; 
import { Animation } from '@components/shared/Animation';

export class Contact extends Component {
    static displayName = Contact.name;

    constructor(props) {
        super(props);

        this.ICONS = {
            //PERSON: <div className='icon'><img src='/files/images/person_icon.svg' alt='Ikona osoby' className='' /></div>,
            PHONE: <div className='icon'><img src='/files/images/phone_icon.svg' alt='Ikona telefonu' className='' /></div>,
            EMAIL: <div className='icon'><img src='/files/images/email_icon.svg' alt='Ikona e-mailu' className='' /></div>,
            LOCATION: <div className='icon'><img src='/files/images/location_icon.svg' alt='Ikona lokace' className='' /></div>,
            CLOCK: <div className='icon'><img src='/files/images/clock_icon.svg' alt='Ikona hodin' className='' /></div>,
        };
    }

    RenderInfo = (phone, email, worktime) => {
        return <div className='d-flex flex-column'>
            <div className='d-flex align-items-center'>
                {this.ICONS.PHONE}
                {this.props.translate(phone)}
            </div>
            {email != null &&
                <div className='d-flex align-items-center'>
                    {this.ICONS.EMAIL}
                    {this.props.translate(email)}
                </div>
            }
            <div className='d-flex align-items-center'>
                {this.ICONS.CLOCK}
                {this.props.translate(worktime)}
            </div>
        </div>
    }

    RenderHotLine = (heading, phone, email, worktime) => {
        return <div className='hotLine px-3 py-2'>
            <div className='fw-bold mb-2'>
                {this.props.translate(heading)}
            </div>
            {this.RenderInfo(phone, email, worktime)}
        </div>
    }

    render() {
        return (
            <>
                <section id='contact' className={`mb-1`}>
                    <h1 className=''>{this.props.translate("s_navlink_contact")}</h1>
                    <div className='tiles d-flex flex-wrap justify-content-between align-items-stretch'>
                        <div className='tileContainer '>
                            <Animation in={'fadeIn'} out={'fadeOut'} component={
                                <div className='tile p-4 h-100'>
                                    <h5 className='redText fw-bold'>
                                        {this.props.translate("h_contact_headquarters")}
                                    </h5>
                                    <div>
                                        {this.props.translate("h_contact_headquarters_address")}
                                    </div>
                                    <div className='my-4'>
                                        {this.props.translate("h_contact_ico")}
                                        {this.props.translate("h_contact_dic")}
                                    </div>
                                    {this.RenderInfo("h_contact_headquarters_phone", "h_contact_headquarters_email", "h_contact_headquarters_worktime")}
                                </div>
                            } /> 
                        </div>
                        <div className='tileContainer'>
                            <Animation in={'fadeIn'} out={'fadeOut'} component={
                                <div className='tile p-4'>
                                    <h5 className='redText fw-bold'>
                                        {this.props.translate("h_contact_vm_heading")}
                                    </h5>
                                    {this.RenderHotLine("h_contact_vm_hotline_heading", "h_contact_vm_phone", "h_contact_vm_email", "h_contact_vm_worktime")}
                                    <div className='fw-bold mb-2 mt-4'>
                                        {this.props.translate("h_contact_vm_technicians_heading")}
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div className='me-2'>
                                            {this.ICONS.PHONE}
                                        </div>
                                        <div className='d-flex'>
                                            <div className='me-3'>
                                                <div>Stanislav Špelina</div>
                                                <div>Jaroslav Trojan</div>
                                                <div>Jan Navrátil</div>
                                            </div>
                                            <div>
                                                <div>603 412 309</div>
                                                <div>603 259 038</div>
                                                <div>603 810 492</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            } /> 
                        </div>
                        <div className='tileContainer'>
                            <Animation in={'fadeIn'} out={'fadeOut'} component={
                                <div className='tile p-4'>
                                    <h5 className='redText fw-bold'>
                                        {this.props.translate("h_dispensing_machines")}
                                    </h5>
                                    {this.RenderHotLine("h_contact_ad_hotline_heading", "h_contact_ad_hotline_phone", "h_contact_ad_hotline_email", "h_contact_ad_hotline_worktime")}
                                    <div className='fw-bold mb-1 mt-4'>
                                        {this.props.translate("h_contact_ad_address_heading")}
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div className='border-end pe-2 pe-sm-4 me-sm-3'>
                                            {this.props.translate("h_contact_ad_address")}
                                        </div>
                                        {this.RenderInfo("h_contact_ad_phone", null, "h_contact_ad_worktime")}
                                    </div>
                                </div>
                            } /> 
                        </div>
                    </div>
                </section>
                <Animation in={'flyInAnimation up'} out={'flyOutAnimation down'} component={
                    <ContactForm link={this.props.link.subLinks.contactForm} translate={this.props.translate} />
                } /> 
            </>
        );
    }
}
