import React, { Component } from 'react'; 
import { Button } from '@components/shared/Button';
import { Spinner } from '@components/shared/Spinner';
import { InfoMessage } from '@components/shared/InfoMessage';
import { SliderOnOff } from '@components/shared/SliderOnOff';
import axios from "axios";
import AdmResponseHelper from '@model/helper/AdmResponseHelper';

export class NewPage extends Component {
    static displayName = NewPage.name;

    constructor(props) {
        super(props);

        this.state = {
            name: "",
            nameEmpty: false,

            prefix: "",
            prefixEmpty: false,

            isActive: false,
            internalNote: "",

            response: null,
            loading: false
        };

        this.responseHelper = new AdmResponseHelper();
        this.loginHeader = {
            headers: {
                'Header-Authority': JSON.stringify({ Token: this.props.loggedAdmin.loginToken })
            }
        };
    }

    componentDidMount() {
        document.body.classList.add('noScroll');
    }

    componentWillUnmount() {
        document.body.classList.remove('noScroll');
    }

    HandleSetName = (val) => {
        this.HandleClearResponse();
        this.setState({ ...this.state, name: val, nameEmpty: false });
    }

    HandleSetPrefix = (val) => {
        this.HandleClearResponse();
        this.setState({ ...this.state, prefix: val, prefixEmpty: false });
    }

    HandleSetInternalNote = (val) => {
        this.HandleClearResponse();
        this.setState({ ...this.state, internalNote: val });
    }

    HandleToggleActive = () => {
        this.HandleClearResponse();
        this.setState((prevState) => ({...prevState, isActive: !this.state.isActive}));
    }

    HandleClearResponse = () => {
        this.setState((prevState) => ({ ...prevState, response: null, prefixEmpty: false, nameEmpty: false }));
    }

    CreatePage = () => {

        let nameEmpty = this.state.name.length <= 0;
        let prefixEmpty = this.state.prefix.length <= 0;

        this.setState({
            ...this.state,
            prefixEmpty: prefixEmpty,
            nameEmpty: nameEmpty,
            response: null,
            loading: (!nameEmpty)
        });

        if (nameEmpty || prefixEmpty)
            return;

        axios
            .post("/api/adm/page/create", {
                Name: this.state.name,
                IsActive: this.state.isActive,
                InternalNote: this.state.internalNote,
                TranslationPrefix: this.state.prefix
            }, this.loginHeader)
            .then((resp) => {
                let data = resp.data;

                this.setState((prevState) => ({
                    ...prevState,
                    response: data.responseCode,
                }));

                if (data.responseCode == 200) {
                    this.props.GetPageList();
                    setTimeout(() => {
                        this.props.HandleCloseForm();
                    }, 200);
                }
            })
            .catch(() => {
                this.setState((prevState) => ({ ...prevState, response: 500 }));
            })
            .finally(() => {
                this.setState((prevState) => ({ ...prevState, loading: false }));
            });
    }

    RenderResponse = () => {

        switch (this.state.response) {
            case 200:
                return <InfoMessage text={"Záznam byl úspěšně založen!"} isSuccess />;
            case 400:
                return <InfoMessage text={"Chyba v zadaných datech."} />;
            case 401:
                return <InfoMessage text={"Neplatné přihlášení!"} />;
            case 500:
                return <InfoMessage text={"Vyskytla se nečekaná chyba."} />;
            default:
                return;
        }
    }

    render() {
        return (
            <div className='form border rounded p-3 fitH'>
                <div className='d-flex align-items-center justify-content-between mb-3'>
                    <h2 className='m-0'>Přidat stránku</h2>
                    <div className='d-flex justify-content-end'>
                        <Button text={<i className="fa-solid fa-xmark"></i>} OnClick={() => this.props.HandleCloseForm()} />
                    </div>
                </div>
                <div className='position-relative'>
                    {this.state.loading &&
                        <Spinner absolute whiteBg />
                    }
                    <div className={`d-flex flex-column mb-3 col-12 ${this.state.nameEmpty ? 'inputError' : ''}`}>
                        <label htmlFor='name'>Název*</label>
                        <input type='text' id='name' className={`ps-2`} value={this.state.name} onChange={(e) => this.HandleSetName(e.target.value)} />
                    </div>
                    <div className={`d-flex flex-column mb-3 col-12 ${this.state.prefixEmpty ? 'inputError' : ''}`}>
                        <label htmlFor='prefix'>Prefix překladu*</label>
                        <input type='text' id='prefix' className={`ps-2`} value={this.state.prefix} onChange={(e) => this.HandleSetPrefix(e.target.value)} />
                    </div>
                    <div className='mb-2'>
                        <SliderOnOff text={'Aktivní'} isChecked={this.state.isActive} OnChange={() => this.HandleToggleActive()} />
                    </div>
                    <div className={`d-flex flex-column mb-3 col-12`}>
                        <label htmlFor='note'>Interní popis</label>
                        <textarea type='text' id='note' className={`ps-2`} value={this.state.internalNote} onChange={(e) => this.HandleSetInternalNote(e.target.value)} />
                    </div>
                    <div className={`d-flex justify-content-between`}>
                        <div className='d-flex flex-column'>
                            {this.responseHelper.RenderCreateResponse(this.state.response)}
                            {this.state.nameEmpty && <InfoMessage text={"Název není vyplněn!"} />}
                            {this.state.prefixEmpty && <InfoMessage text={"Prefix překladu není vyplněn!"} />}
                        </div>
                        <Button className='col-2' text={"Přidat"} OnClick={() => this.CreatePage()} />
                    </div>
                </div>
            </div>
        );
    }

}
