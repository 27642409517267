import React, { Component } from 'react'; 
import { Button } from '@components/shared/Button';
import axios from "axios";
import LoginHelper from '@model/helper/LoginHelper'
import { Spinner } from '@components/shared/Spinner';
import AdmResponseHelper from '@model/helper/AdmResponseHelper';
import { SliderOnOff } from '@components/shared/SliderOnOff';

export class EditPage extends Component {
    static displayName = EditPage.name;

    constructor(props) {
        super(props);
        this.target = this.props.targetPage;
        this.loginController = new LoginHelper();

        this.state = {
            responseEdit: null,
            responseDelete: null,
            loading: false,

            isActive: this.target.isActive,
            name: this.target.name,
            internalNote: this.target.internalNote,

            prefix: this.target.translationPrefix,
            prefixEmpty: false,

            showDeleteConfirmation: false,
            nameEmpty: false,

        };

        this.responseHelper = new AdmResponseHelper();
        this.loginHeader = {
            headers: {
                'Header-Authority': JSON.stringify({ Token: this.props.loggedAdmin.loginToken })
            }
        };
    }

    componentDidMount() {
        document.body.classList.add('noScroll');
    }

    componentWillUnmount() {
        document.body.classList.remove('noScroll');
    }

    DeletePage = () => {

        this.setState((prevState) => ({ ...prevState, loading: true }));

        axios
            .delete(`/api/adm/page/delete/${this.target.id}`, this.loginHeader)
            .then((response) => {
                let data = response.data;
                this.setState((prevState) => ({ ...prevState, responseDelete: data.responseCode }));

                if (data.responseCode == 200) {
                    this.props.GetPageList();
                    setTimeout(() => {
                        this.props.HandleCloseForm();
                    }, 200);
                }

            }).catch(() => {
                this.setState((prevState) => ({ ...prevState, responseDelete: 500 }));
            }).finally(() => {
                this.setState((prevState) => ({ ...prevState, loading: false }));
            });
    }

    EditPage = () => {

        this.setState((prevState) => ({ ...prevState, loading: true }));

        axios
            .patch("/api/adm/page/edit", {
                Id: this.target.id,
                Name: this.state.name,
                IsActive: this.state.isActive,
                InternalNote: this.state.internalNote,
                TranslationPrefix: this.state.prefix
            }, this.loginHeader)
            .then((response) => {
                let data = response.data;
                this.setState((prevState) => ({ ...prevState, responseEdit: data.responseCode }));

                if (data.responseCode == 200)
                    this.props.GetPageList();

            }).catch(() => {
                this.setState((prevState) => ({ ...prevState, responseEdit: 500 }));
            }).finally(() => {
                this.setState((prevState) => ({ ...prevState, loading: false }));
            });
    }

    HandleToggleDeleteConfirmation = () => {
        this.setState((prevState) => ({ ...prevState, showDeleteConfirmation: !this.state.showDeleteConfirmation }));
    }

    HandleSetName = (val) => {
        this.HandleClearResponse();
        this.setState((prevState) => ({ ...prevState, name: val }));
    }

    HandleSetPrefix = (val) => {
        this.HandleClearResponse();
        this.setState((prevState) => ({ ...prevState, prefix: val, prefixEmpty: false }));
    }

    HandleSetInternalNote = (val) => {
        this.HandleClearResponse();
        this.setState((prevState) => ({ ...prevState, internalNote: val }));
    }

    HandleToggleIsActive = () => {
        this.HandleClearResponse();
        this.setState((prevState) => ({ ...prevState, isActive: !this.state.isActive }));
    }

    HandleClearResponse = () => {
        this.setState((prevState) => ({
            ...prevState,
            responseDelete: null,
            responseEdit: null
        }));
    }

    render() {
        return (
            <div className='form position-absolute border rounded p-3'>
                <div className='d-flex align-items-center justify-content-between mb-3'>
                    <h2 className='m-0 d-flex flex-column flex-md-row align-items-md-center'>
                        Upravit stránku
                        {!this.target.deletable && <small className='redText opacity-50 ms-0 ms-md-2'>Nesmazatelná</small>}
                    </h2>
                    <div className='d-flex justify-content-end'>
                        <Button text={<i className="fa-solid fa-xmark"></i>} OnClick={() => this.props.HandleCloseForm()} />
                    </div>
                </div>
                <div className='position-relative'>
                    {this.state.loading &&
                        <Spinner absolute whiteBg />
                    }
                    <div className={`d-flex flex-column mb-3 col-12`}>
                        <label htmlFor='id'>ID <i className="ms-1 fa-solid fa-lock"></i></label>
                        <input type='text' id='id' className='ps-2 readonly' readOnly value={this.target.id} />
                    </div>
                    <div className={`d-flex flex-column mb-3 col-12`}>
                        <label htmlFor='name'>Název {!this.target.deletable && <i className="ms-1 fa-solid fa-lock"></i>}</label>
                        <input type='text' id='name' className={`ps-2 ${this.target.deletable ? '' : 'readonly'}`} readOnly={!this.target.deletable} value={this.state.name} onChange={(e) => this.HandleSetName(e.target.value)} />
                    </div>
                    <div className={`d-flex flex-column mb-3 col-12 ${this.state.prefixEmpty ? 'inputError' : ''}`}>
                        <label htmlFor='prefix'>Prefix překladu <i className="ms-1 fa-solid fa-lock"></i></label>
                        <input type='text' id='prefix' className={`ps-2 ${this.target.deletable ? '' : 'readonly'}`} value={this.state.prefix} onChange={(e) => this.HandleSetPrefix(e.target.value)} />
                    </div>
                    <div className='my-2'>
                        <SliderOnOff text={'Aktivní'} isChecked={this.state.isActive} readOnly={!this.target.deletable} OnChange={() => this.HandleToggleIsActive()} />
                    </div>
                    <div className={`d-flex flex-column mb-3 col-12`}>
                        <label htmlFor='note'>Interní popis</label>
                        <textarea type='text' id='note' className='ps-2' value={this.state.internalNote} onChange={(e) => this.HandleSetInternalNote(e.target.value)} />
                    </div>
                    <div className='d-flex justify-content-between'>
                        <div className='d-flex'>
                            <Button className='col-2' text={this.state.showDeleteConfirmation ? 'Zrušit' : 'Smazat'} OnClick={() => this.HandleToggleDeleteConfirmation()} disabled={!this.target.deletable} />
                            {this.state.showDeleteConfirmation &&
                                <div className='ms-1'><Button className='col-2 m-5' text={'Opravdu smazat'} OnClick={() => this.DeletePage()} /></div>
                            }
                        </div>
                        <Button className='col-2' text={"Upravit"} OnClick={() => this.EditPage()} />
                    </div>
                    <div className='d-flex flex-column mb-2 mt-3'>
                        {this.state.showDeleteConfirmation &&
                            <div className=''>{this.responseHelper.RenderCascadeDeleteWarning()}</div>
                        }
                        {this.responseHelper.RenderDeleteResponse(this.state.responseDelete)}
                        {this.responseHelper.RenderEditResponse(this.state.responseEdit)}
                    </div>
                </div>
            </div>
        );
    }

}
