import React, { Component } from 'react';
import '@styles/shared/arrowUp.scss';

export class ArrowUp extends Component {
    static displayName = ArrowUp.name;

    constructor(props) {
        super(props);
        this.state = {
            show: false
        }
    }

    ScrollTop = () => {
        window.scroll(0, 0);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.HandleScroll);
    }

    HandleScroll = () => {
        let show = window.scrollY > 200;
        this.setState((prevState) => ({ ...prevState, show: show }))
    }

    render() {
        return (
            <div className={`arrowUp position-fixed ${this.state.show ? 'show' : 'hide'}`} role="button" onClick={() => this.ScrollTop()}>
                <i className={`redText fa-solid fa-chevron-up`}></i>
            </div>
        );
    }
}
