import React, { Component } from 'react'; 
import { Button } from '@components/shared/Button';
import { InfoMessage } from '@components/shared/InfoMessage';
import axios from "axios";
import LoginHelper from '@model/helper/LoginHelper'
import { Spinner } from '@components/shared/Spinner';
import { SliderOnOff } from '@components/shared/SliderOnOff';
import AdmResponseHelper from '@model/helper/AdmResponseHelper';
import { ToolTip } from '@components/shared/ToolTip';
export class EditUser extends Component {
    static displayName = EditUser.name;

    constructor(props) {
        super(props);
        this.target = this.props.targetUser;
        this.loginController = new LoginHelper();

        this.defaultLoginDuration = 3;
        this.state = {

            passwordChange: false,
            password: "",
            passwordTooShort: false,
            passwordVisible: false,
            idRole: this.target.role.id,

            loginDuration: this.target.loginDuration,

            isActive: this.target.isActive,
            description: this.target.description,
            loginExpire: this.target.loginExpire,

            responseEdit: null,
            responseDelete: null,
            responseLogOff: null,
            loading: false,

            showDeleteConfirmation: false
        };

        this.minPasswordLength = 6;
        this.responseHelper = new AdmResponseHelper();
        this.loginHeader = {
            headers: {
                'Header-Authority': JSON.stringify({ Token: this.props.loggedAdmin.loginToken })
            }
        };
    }

    componentDidMount() {
        document.body.classList.add('noScroll');
    }

    componentWillUnmount() {
        document.body.classList.remove('noScroll');
    }

    HandleTogglePassword = () => {
        this.setState((prevState) => ({ ...prevState, passwordVisible: !this.state.passwordVisible }));
    }

    HandleSetUsername = (val) => {
        this.setState((prevState) => ({ ...prevState, username: val, usernameEmpty: false }));
    }

    HandleSetPassword = (val) => {
        this.setState((prevState) => ({ ...prevState, password: val, passwordTooShort: false }));
    }

    HandleSetIdRole = (val) => {
        if (this.target.isRoot)
            return;
        this.setState((prevState) => ({ ...prevState, idRole: val, passwordTooShort: false }));
    }

    HandleSetLoginDuration = (val) => {

        let duration;
        try {
            duration = parseInt(val);
            duration = Math.round(duration);
            if (duration < 1 || duration == undefined || isNaN(duration))
                duration = 1;
        } catch {
            duration = this.defaultLoginDuration;
        }

        this.setState((prevState) => ({ ...prevState, loginDuration: duration, response: null }));
    }

    DeleteUser = () => {

        this.setState((prevState) => ({ ...prevState, loading: true }));

        axios
            .delete(`/api/adm/user/delete/${this.target.id}`, this.loginHeader)
            .then((response) => {
                let data = response.data;
                this.setState({ responseDelete: data.responseCode });

                if (data.responseCode == 200) {
                    this.props.GetUserList();
                    setTimeout(() => {
                        this.props.HandleCloseForm();
                    }, 200);
                }

            }).catch(() => {
                this.setState((prevState) => ({ ...prevState, responseDelete: 500 }));
            }).finally(() => {
                this.setState((prevState) => ({ ...prevState, loading: false }));
            });
    }

    EditUser = () => {

        let passwordTooShort = false;
        if (this.state.passwordChange)
            passwordTooShort = this.state.password.length < this.minPasswordLength;

        this.setState((prevState) => ({
            ...prevState,
            passwordTooShort: passwordTooShort,
            responseEdit: null
        }));

        if (passwordTooShort)
            return;

        this.setState((prevState) => ({
            ...prevState,
            loading: true
        }));

        axios
            .patch("/api/adm/user/edit", {
                Id: this.target.id,
                Password: this.state.passwordChange ? this.state.password : null,
                Description: this.state.description,
                IsActive: this.state.isActive,
                IdRole: this.state.idRole,
                LoginDuration: this.state.loginDuration,
            }, this.loginHeader)
            .then((response) => {
                let data = response.data;
                this.props.GetUserList();
                this.setState({ ...this.state, responseEdit: data.responseCode });
            }).catch(() => {
                this.setState({ ...this.state, responseEdit: 500 });
            }).finally(() => {
                this.setState((prevState) => ({ ...prevState, loading: false }));
            });;
    }

    HandleUserLogOff = () => {

        let token = this.props.loggedAdmin.loginToken;
        let id = this.target.id;
        this.loginController.LogOff(token, id).then(logOffResponse => {

            this.setState((prevState) => ({
                ...prevState,
                responseLogOff: logOffResponse.responseCode
            }));

            if (logOffResponse.responseCode == 200) {
                this.props.GetUserList();
                this.setState((prevState) => ({
                    ...prevState,
                    loginExpire: ""
                }));
            }
        });
    }

    HandleTogglePasswordChange = () => {
        this.setState((prevState) => ({ ...prevState, passwordChange: !this.state.passwordChange }));
    }

    HandleToggleDeleteConfirmation = () => {
        this.setState((prevState) => ({ ...prevState, showDeleteConfirmation: !this.state.showDeleteConfirmation }));
    }

    HandleToggleIsActive = () => {
        this.setState((prevState) => ({ ...prevState, isActive: !this.state.isActive }));
    }

    render() {
        return (
            <div className='form position-absolute border rounded p-3'>
                <div className='d-flex align-items-center justify-content-between mb-3'>
                    <h2 className='m-0'>Upravit uživatele {this.target.isRoot && <small className='redText opacity-50'>Root</small>}</h2>
                    <div className='d-flex justify-content-end'>
                        <Button text={<i className="fa-solid fa-xmark"></i>} OnClick={() => this.props.HandleCloseForm()} />
                    </div>
                </div>
                <div className='position-relative'>
                    {this.state.loading &&
                        <Spinner absolute whiteBg />
                    }
                    <div className={`d-flex flex-column mb-3 col-12`}>
                        <label htmlFor='id'>ID <i className="ms-1 fa-solid fa-lock"></i></label>
                        <input type='text' id='id' name='id' className='ps-2 readonly' readOnly value={this.target.id} />
                    </div>
                    <div className='d-flex flex-column '>
                        <div className={`d-flex flex-column mb-3 col-12 ${this.state.usernameEmpty ? 'inputError' : ''}`}>
                            <label htmlFor='username'>Username <i className="ms-1 fa-solid fa-lock"></i></label>
                            <input type='text' id='username' name='username' className={`ps-2 readonly`} readOnly value={this.target.username} />
                        </div>
                        <div className={`d-flex flex-column mb-3 col-12`}>
                            <label htmlFor='loginDuration'>Délka přihlášení (hodiny)*</label>
                            <input type='number' id='loginDuration' className={`ps-2`} value={this.state.loginDuration} onChange={(e) => this.HandleSetLoginDuration(e.target.value)} />
                        </div>
                        <div className={`d-flex flex-column mb-3 col-12`}>
                            <label htmlFor='role'>
                                Role
                                {this.target.isRoot && <i className="ms-1 fa-solid fa-lock"></i>}
                            </label>
                            <select type='text' id='role' className={`p-1 ${this.target.isRoot ? 'readonly' : ''}`} value={this.state.idRole} readOnly={this.target.isRoot} onChange={(e) => this.HandleSetIdRole(e.target.value)}>
                                {this.props.userRoleList.map(role => {
                                    return (
                                        <option key={role.id} value={role.id}>{role.name}</option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className='my-1 mb-2'>
                            <div className='d-flex align-items-center'>
                                <SliderOnOff text={'Změnit heslo'} isChecked={this.state.passwordChange} OnChange={() => this.HandleTogglePasswordChange()} />
                                <div className='ms-3 ps-3 border-start'>
                                    <SliderOnOff text={'Aktivní'} isChecked={this.state.isActive} OnChange={() => this.HandleToggleIsActive()} readOnly={this.target.isRoot} />
                                </div>
                                <div className='ms-1 w-auto'>
                                    <ToolTip text={'Deaktivováním se zamezí možnost přihlášení daného uživatele'} />
                                </div>
                            </div>
                            {this.state.passwordChange &&
                                <div className={`d-flex flex-column mb-3 mt-2 col-12 position-relative ${this.state.passwordTooShort ? 'inputError' : ''}`}>
                                    <label htmlFor='password'>Nové heslo*</label>
                                    <input
                                        id='password' name='password'
                                        className='ps-2'
                                        type={`${this.state.passwordVisible ? 'text' : 'password'}`}
                                        value={this.state.password}
                                        onChange={(e) => this.HandleSetPassword(e.target.value)}
                                    />
                                    <i role='button' className={`position-absolute pb-2 pe-1 bottom-0 end-0 fa-regular fa-eye${this.state.passwordVisible ? '-slash' : ''}`}
                                        onClick={() => this.HandleTogglePassword()}
                                    ></i>
                                </div>
                            }
                        </div>
                        <div className={`d-flex flex-column mb-3 col-12`}>
                            <label htmlFor='description'>Popis</label>
                            <textarea type='text' id='description' name='description' className='ps-2' value={this.state.description} onChange={(e) => this.setState((prevState) => ({ ...prevState, description: e.target.value, responseEdit: null }))} />
                        </div>
                        <div className={`d-flex flex-column mb-3 col-12`}>
                            <label htmlFor='createdAt'>Založen <i className="ms-1 fa-solid fa-lock"></i></label>
                            <input type='text' id='createdAt' name='id' className='ps-2 readonly' readOnly value={this.target.createdAt} />
                        </div>
                        <div className={`d-flex flex-column mb-3 col-12`}>
                            <label htmlFor='lastLogin'>Naposledy přihlášen <i className="ms-1 fa-solid fa-lock"></i></label>
                            <input type='text' id='lastLogin' className='ps-2 readonly' readOnly value={this.target.lastLogin} />
                        </div>
                        {(this.state.loginExpire != null && this.state.loginExpire != "") &&
                            <div className={`d-flex flex-column mb-3 col-12 position-relative`}>
                                <label htmlFor='loginExpire'>Přihlášen do <i className="ms-1 fa-solid fa-lock"></i></label>
                                <input type='text' id='loginExpire' name='id' className='ps-2 readonly' readOnly value={this.state.loginExpire} />
                                <a role='button' className='position-absolute pe-1 bottom-0 end-0' onClick={() => this.HandleUserLogOff()}>Odhlásit</a>
                            </div>
                        }
                    </div>
                    <div className='d-flex justify-content-between'>
                        <div className='d-flex'>
                            <Button className='col-2' text={this.state.showDeleteConfirmation ? 'Zrušit' : 'Smazat'} OnClick={() => this.HandleToggleDeleteConfirmation()} disabled={this.target.isRoot} />
                            {this.state.showDeleteConfirmation &&
                                <div className='ms-1'><Button className='col-2 m-5' text={'Opravdu smazat'} OnClick={() => this.DeleteUser()} /></div>
                            }
                        </div>
                        <Button className='col-2' text={"Upravit"} OnClick={() => this.EditUser()} />
                    </div>
                    <div className='d-flex flex-column mb-2 mt-3'>
                        {this.responseHelper.RenderDeleteResponse(this.state.responseDelete)}
                        {this.responseHelper.RenderEditResponse(this.state.responseEdit)}
                        {this.state.passwordTooShort && <InfoMessage text={"Heslo je příliš krátké!"} />}
                    </div>
                </div>
            </div>
        );
    }

}
