import React, { Component } from 'react';
import '@styles/adm/slideShow.scss';
import axios from "axios";
import AdmResponseHelper from '@model/helper/AdmResponseHelper';
import { SlideShow as SlideShowPublic } from '@components/public/pages/home/components/SlideShow';
import { SliderOnOff } from '@components/shared/SliderOnOff';
import { SlideShowList } from './components/SlideShowList';
import { SlideEdit } from './components/SlideEdit';
import AdmHelper from '@model/helper/AdmHelper';
import { ToolTip } from '@components/shared/ToolTip';
import ShowMore from '@components/shared/ShowMore';

export class SlideShow extends Component {
    static displayName = SlideShow.name;

    constructor(props) {
        super(props);

        this.VIEWS = {
            Create: 'create',
            Edit: 'edit',
            List: 'list',
        };

        this.PREVIEWTYPE = {
            Mobile: 'mobil',
            Tablet: 'tablet',
            Pc: 'pc',
            PcLarge: 'pc+',
        }

        this.state = {

            slideList: [],
            slideListResponse: null,
            slideListLoading: true,

            translationList: [],
            translationListResponse: null,
            translationListLoading: true,

            fileList: [],
            fileListResponse: null,
            fileListLoading: true,

            previewSlides: [],
            previewVisible: true,
            previewIncludeInactive: false,
            previewForceShowControls: false,

            currentView: this.VIEWS.List,
            currentPreviewType: this.PREVIEWTYPE.Pc,

            currentEditSlide: null,

            previewSlideShowRef: {
                main: { ref: React.createRef() }
            }
        }

        this.responseHelper = new AdmResponseHelper();
        this.helper = new AdmHelper(); 
        this.loginHeader = {
            headers: {
                'Header-Authority': JSON.stringify({ Token: this.props.loggedAdmin.loginToken })
            }
        };
    }

    componentDidMount() {
        this.GetSlideList();
        this.GetFileList();
        this.GetSlideShowTranslationList();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            (prevState.previewIncludeInactive != this.state.previewIncludeInactive) ||
            (prevState.currentView != this.state.currentView && this.state.currentView == this.VIEWS.List) ||
            (prevState.currentPreviewType != this.state.currentPreviewType)
        )
        {
            this.HandleSetPreviewSlides(this.state.slideList);
        }
    }

    HandleTogglePreview = () => {
        this.setState((prevState) => ({ ...prevState, previewVisible: (!this.state.previewVisible) }));
    }

    HandleTogglePreviewForceShowControls = () => {
        this.setState((prevState) => ({ ...prevState, previewForceShowControls: (!this.state.previewForceShowControls) }));
    }

    HandleTogglePreviewIncludeInactive = () => {
        this.setState((prevState) => ({ ...prevState, previewIncludeInactive: !this.state.previewIncludeInactive }));
    }

    GetSlideList = () => {

        this.setState((prevState) => ({ ...prevState, slideListLoading: true }));

        return axios
            .get("/api/adm/slideshow/list", this.loginHeader)
            .then((response) => {
                let data = response.data;
                //console.log(data);

                this.HandleSetPreviewSlides(data.slideShowList);
                this.setState((prevState) => ({ ...prevState, slideListResponse: data.responseCode, slideList: data.slideShowList }));
            }).catch(() => {
                this.setState((prevState) => ({ ...prevState, slideListResponse: 500 }));
            }).finally(() => {
                this.setState((prevState) => ({ ...prevState, slideListLoading: false }));
            });
    }

    GetFileList = () => {

        return axios
            .post("/api/adm/file/image/list", {
                PaginationCount: null,
            }, this.loginHeader)
            .then((response) => {
                let data = response.data;
                this.setState((prevState) => ({ ...prevState, fileListResponse: data.responseCode, fileList: data.fileList }));
            }).catch(() => {
                this.setState((prevState) => ({ ...prevState, fileListResponse: 500 }));
            }).finally(() => {
                this.setState((prevState) => ({ ...prevState, fileListLoading: false }));
            });
    }

    GetSlideShowTranslationList = async () => {

        axios
            .get("/api/adm/page/list", this.loginHeader)
            .then((response) => {
                let data = response.data;
                let slideShowPageId = data.pageList.find(p => p.name == 'slideShow');
                if (slideShowPageId)
                    slideShowPageId = slideShowPageId.id;

                return axios
                    .post("/api/adm/translation/list", {
                        IdPage: slideShowPageId ?? null,
                        PaginationCount: null,
                    }, this.loginHeader)
                    .then((response) => {
                        let data = response.data;
                        this.setState((prevState) => ({ ...prevState, translationListResponse: data.responseCode, translationList: data.translationList }));
                    }).catch(() => {
                        this.setState((prevState) => ({ ...prevState, translationListResponse: 500 }));
                    }).finally(() => {
                        this.setState((prevState) => ({ ...prevState, translationListLoading: false }));
                    });
            });
    }

    RenderResponse = (resp) => {

        switch (resp) {
            case 400:
            case 401:
                return this.responseHelper.RenderLoginInvalid();
            case 500:
                return this.responseHelper.RenderSomethingWentWrong();
            default:
                return;
        }
    }

    HandleSetPreviewSlides = (slides) => {
        slides = this.GetPreviewSlides(slides);
        this.setState((prevState) => ({ ...prevState, previewSlides: slides, previewVisible: true }));
    }

    GetPreviewSlides = (slides) => {

        if (slides.length <= 0)
            return [];

        if (this.state.currentView == this.VIEWS.List) {
            slides = this.state.previewIncludeInactive ? slides : slides.filter(s => s.isActive);
        }

        for (let i in slides) {
            let slide = slides[i];
            slides[i] = {
                ...slide,
                heading: this.helper.GetLocalizationCzechOrOther(this.GetTranslationBasedOnId(slide.idTranslationHeading), false, false),
                text: this.helper.GetLocalizationCzechOrOther(this.GetTranslationBasedOnId(slide.idTranslationText,), false, false)
            }
        }

        return slides;
    }

    GetTranslationBasedOnId = (id) => {
        return this.state.translationList.find(t => t.id == id);
    }

    HandleOpenCreateSlide = () => {
        this.setState((prevState) => ({ ...prevState, currentView: this.VIEWS.Create}));
    }

    HandleOpenEditSlide = (slide) => {
        this.setState((prevState) => ({ ...prevState, currentView: this.VIEWS.Edit, currentEditSlide: slide }));
    }

    HandleOpenListSlide = (loadSlideShow = false) => {
        if (loadSlideShow)
            this.GetSlideList();
        this.setState((prevState) => ({ ...prevState, currentView: this.VIEWS.List }));
    }

    RenderCurrentView = () => {

        switch (this.state.currentView) {
            case this.VIEWS.Create:
                return <SlideEdit loggedAdmin={this.props.loggedAdmin} HandleOpenListSlide={this.HandleOpenListSlide} fileList={this.state.fileList} translationList={this.state.translationList} HandleSetPreviewSlides={this.HandleSetPreviewSlides} />
            case this.VIEWS.Edit:
                return <SlideEdit currentEditSlide={this.state.currentEditSlide} loggedAdmin={this.props.loggedAdmin} HandleOpenListSlide={this.HandleOpenListSlide} fileList={this.state.fileList} translationList={this.state.translationList} HandleSetPreviewSlides={this.HandleSetPreviewSlides} />
            case this.VIEWS.List:
            default:
                return <SlideShowList HandleOpenEditSlide={this.HandleOpenEditSlide} slideList={this.state.slideList} slideListLoading={this.state.slideListLoading} HandleOpenCreateSlide={this.HandleOpenCreateSlide} />

        }
    }

    RenderPreviewSlideShow = () => {

        let newSlides = JSON.parse(JSON.stringify(this.state.previewSlides));
        for (let i in newSlides) {

            switch (this.state.currentPreviewType) {
                case this.PREVIEWTYPE.Mobile:
                    newSlides[i].imgTablet = newSlides[i].imgMobile;
                    newSlides[i].imgPc = newSlides[i].imgMobile;
                    newSlides[i].imgPcLarge = newSlides[i].imgMobile;
                    break;
                case this.PREVIEWTYPE.Tablet:
                    newSlides[i].imgMobile = newSlides[i].imgTablet;
                    newSlides[i].imgPc = newSlides[i].imgTablet;
                    newSlides[i].imgPcLarge = newSlides[i].imgTablet;
                    break;
                case this.PREVIEWTYPE.Pc:
                    newSlides[i].imgMobile = newSlides[i].imgPc;
                    newSlides[i].imgTablet = newSlides[i].imgPc;
                    newSlides[i].imgPcLarge = newSlides[i].imgPc;
                    break;
                case this.PREVIEWTYPE.PcLarge:
                    newSlides[i].imgMobile = newSlides[i].imgPcLarge;
                    newSlides[i].imgTablet = newSlides[i].imgPcLarge;
                    newSlides[i].imgPc = newSlides[i].imgPcLarge;
                    break;
                default:
                    break;
            }           
        }

        return (
            <div className={`previewSlideshow d-flex justify-content-center`}>
                <div className={`previewTypeContainer ${this.state.currentPreviewType}`}>
                    <SlideShowPublic link={this.state.previewSlideShowRef} slideShow={newSlides} isAdm forceShowControls={this.state.previewForceShowControls} forceMobile={this.state.currentPreviewType === this.PREVIEWTYPE.Mobile} />
                    <div className='container section d-flex flex-column'>
                        <h1 className=''>Ilustrační nadpis</h1>
                        <div>Lorem ipsum dolor sit amet ...</div>
                    </div>
                </div>
            </div>
        );
    }

    ScrollToPreview = () => {

        let ref = this.state.previewSlideShowRef.main.ref.current;
        if (ref == null)
            return;

        let targetY = window.scrollY + ref.getBoundingClientRect().top - this.GetNavHeight();

        window.scrollTo({ top: targetY, behavior: "smooth" });
    }

    GetNavHeight = () => {
        return window.window.innerWidth <= 768 ? 61 : 70
    }

    HandleChangePreviewType = (type) => {
        let validType = Object.keys(this.PREVIEWTYPE).find(pt => pt === type);
        if (!validType)
            return;

        this.setState((prevState) => ({ ...prevState, currentPreviewType: this.PREVIEWTYPE[validType] }));
    }

    RenderPreviewTypes = () => {
        return (
            Object.keys(this.PREVIEWTYPE).map((key, i) => (
                <span role='button' key={key} onClick={() => this.HandleChangePreviewType(key)} className={`text-uppercase previewType ${this.state.currentPreviewType == this.PREVIEWTYPE[key] && 'current'} ${Object.keys(this.PREVIEWTYPE).length - 1 > i && 'border-end me-1 pe-1'}`}>{this.PREVIEWTYPE[key]}</span>
            ))
        );
    }

    render() {
        return (
            <div id='admSlideShow' className='position-relative'>
                {this.RenderResponse(this.state.slideListResponse)}
                {this.RenderResponse(this.state.translationListResponse)}
                {this.RenderResponse(this.state.fileListResponse)}
                <div className='container pb-4'>
                    {this.RenderCurrentView()}
                </div>
                {(!this.state.slideListLoading && (this.state.slideList.length > 0 || this.state.currentView != this.VIEWS.List)) &&
                    <div id='preview' className='d-flex flex-column mt-5'>
                        <div className='border-bottom'>
                            <div className='container d-flex align-items-start justify-content-between justify-content-xl-start align-items-xl-center pb-3'>
                                <div className='d-flex align-items-center' role='button' onClick={() => this.ScrollToPreview() }>
                                    <i className="fa-solid fa-chevron-down me-1"></i>
                                    <h2 className='m-0 me-2'>Výsledný vzhled</h2>                                   
                                </div>
                                <div className='d-flex flex-column flex-xl-row col-xl justify-content-xl-between'>
                                    <div className='d-flex align-items-center mb-2 mb-xl-0 order-xl-2'>
                                        {this.RenderPreviewTypes()}
                                        <div className='ms-2'>
                                            <ToolTip text={'Pouze ilustrační. Pro přesný výsledek na malých zařízeních zmenšete okno prohlížeče.'} />
                                        </div>
                                    </div>
                                    <div className='d-flex flex-column flex-xl-row'>
                                        <div className='mb-2 mb-xl-0 me-xl-2'>
                                            <SliderOnOff text={'Zobrazit'} reversed isChecked={this.state.previewVisible} OnChange={() => this.HandleTogglePreview()} />
                                        </div>
                                        {this.state.currentView == this.VIEWS.List &&
                                            <div className='mb-2 mb-xl-0 me-xl-2'>
                                                <SliderOnOff text={'Zahrnout neaktivní'} reversed isChecked={this.state.previewIncludeInactive} OnChange={() => this.HandleTogglePreviewIncludeInactive()} />
                                            </div>
                                        }
                                        {(this.state.previewSlides.length == 1) &&
                                            <div className=''>
                                                <SliderOnOff text={'Zobrazit ovládací prvky'} reversed isChecked={this.state.previewForceShowControls} OnChange={() => this.HandleTogglePreviewForceShowControls()} />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(this.state.previewVisible && this.state.previewSlides.length > 0) &&
                            this.RenderPreviewSlideShow()
                        }
                    </div>
                }
            </div>
        );
    }
}
