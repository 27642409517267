import React, { Component } from 'react'; 
import { Button } from '@components/shared/Button';
import axios from "axios";
import LoginHelper from '@model/helper/LoginHelper'
import { Spinner } from '@components/shared/Spinner';
import AdmResponseHelper from '@model/helper/AdmResponseHelper';
import { SliderOnOff } from '@components/shared/SliderOnOff';
import { ToolTip } from '@components/shared/ToolTip';
export class EditRole extends Component {
    static displayName = EditRole.name;

    constructor(props) {
        super(props);
        this.target = this.props.targetRole;
        this.loginController = new LoginHelper();

        this.state = {

            name: this.target.name,
            nameEmpty: false,
            description: this.target.description,
            isActive: true,

            responseEdit: null,
            responseDelete: null,

            loading: false,

            showDeleteConfirmation: false
        };

        this.responseHelper = new AdmResponseHelper();
        this.loginHeader = {
            headers: {
                'Header-Authority': JSON.stringify({ Token: this.props.loggedAdmin.loginToken })
            }
        };
    }

    componentDidMount() {
        document.body.classList.add('noScroll');
    }

    componentWillUnmount() {
        document.body.classList.remove('noScroll');
    }

    HandleSetName = (val) => {
        this.HandleDeleteResponses();
        this.setState((prevState) => ({ ...prevState, name: val, nameEmpty: false }));
    }

    HandleToggleIsActive = () => {
        this.HandleDeleteResponses();
        this.setState((prevState) => ({ ...prevState, isActive: this.state.isActive }));
    }

    HandleSetDescription = (val) => {
        this.HandleDeleteResponses();
        this.setState((prevState) => ({ ...prevState, description: val }));
    }

    HandleDeleteResponses = () => {
        this.setState((prevState) => ({ ...prevState, responseDelete: null, responseEdit: null }));
    }

    DeleteRole = () => {

        this.setState((prevState) => ({ ...prevState, loading: true }));

        axios
            .delete(`/api/adm/user/role/delete/${this.target.id}`, this.loginHeader)
            .then((response) => {
                let data = response.data;
                this.setState((prevState) => ({ ...prevState, responseDelete: data.responseCode }));

                if (data.responseCode == 200) {
                    this.props.GetRoleList(true);
                    setTimeout(() => {
                        this.props.HandleCloseForm();
                    }, 200);
                }

            }).catch(() => {
                this.setState((prevState) => ({ ...prevState, responseDelete: 500 }));
            }).finally(() => {
                this.setState((prevState) => ({ ...prevState, loading: false }));
            });
    }

    EditRole = () => {

        let nameEmpty = this.state.name.length <= 0;
        this.setState((prevState) => ({
            ...prevState,
            nameEmpty: nameEmpty,
            loading: !nameEmpty
        }));

        if (nameEmpty)
            return;

        axios
            .patch("/api/adm/user/role/edit", {
                Id: this.target.id,
                Name: this.state.name,
                Description: this.state.description,
                IsActive: this.state.isActive
            }, this.loginHeader)
            .then((response) => {
                let data = response.data;
                if (data.responseCode == 200) {
                    this.props.GetRoleList(true);
                    setTimeout(() => {
                        this.props.HandleCloseForm();
                    }, 200);
                }
                this.setState((prevState) => ({ ...prevState, responseEdit: data.responseCode }));
            }).catch(() => {
                this.setState((prevState) => ({ ...prevState, responseEdit: 500 }));
            }).finally(() => {
                this.setState((prevState) => ({ ...prevState, loading: false }));
            });;
    }

    HandleToggleDeleteConfirmation = () => {
        this.setState((prevState) => ({ ...prevState, showDeleteConfirmation: !this.state.showDeleteConfirmation }));
    }

    render() {
        return (
            <div className='form position-absolute border rounded p-3'>
                <div className='d-flex align-items-center justify-content-between mb-3'>
                    <h2 className='m-0'>Upravit roli {this.target.isRoot && <small className='redText opacity-50'>Root</small>}</h2>
                    <div className='d-flex justify-content-end'>
                        <Button text={<i className="fa-solid fa-xmark"></i>} OnClick={() => this.props.HandleCloseForm()} />
                    </div>
                </div>
                <div className='position-relative'>
                    {this.state.loading &&
                        <Spinner absolute whiteBg />
                    }
                    <div className={`d-flex flex-column mb-3 col-12`}>
                        <label htmlFor='id'>ID <i className="ms-1 fa-solid fa-lock"></i></label>
                        <input type='text' id='id' name='id' className='ps-2 readonly' readOnly value={this.target.id} />
                    </div>
                    <div className={`d-flex flex-column mb-3 col-12 ${this.state.nameEmpty ? 'inputError' : ''}`}>
                        <label htmlFor='name'>Name <i className="ms-1 fa-solid fa-lock"></i></label>
                        <input type='text' id='name' className={`ps-2 readonly`} value={this.state.name} onChange={(e) => this.HandleSetName(e.target.value)} readOnly />
                    </div>
                    <div className='col d-flex mb-2'>
                        <SliderOnOff text={'Aktivní'} isChecked={this.state.isActive} OnChange={() => this.HandleToggleIsActive()} readOnly={this.target.isRoot} />
                        <div className='ms-1 w-auto'>
                            <ToolTip text={'Deaktivováním se zamezí přihlášení uživatelů s danou rolí'} />
                        </div>
                    </div>
                    <div className={`d-flex flex-column mb-3 col-12`}>
                        <label htmlFor='description'>Popis</label>
                        <textarea type='text' id='description' name='description' className='ps-2' value={this.state.description} onChange={(e) => this.HandleSetDescription(e.target.value)} />
                    </div>   
                    <div className='d-flex justify-content-between'>
                        <div className='d-flex'>
                            <Button className='col-2' text={this.state.showDeleteConfirmation ? 'Zrušit' : 'Smazat'} OnClick={() => this.HandleToggleDeleteConfirmation()} disabled />
                            {this.state.showDeleteConfirmation &&
                                <div className='ms-1'><Button className='col-2 m-5' text={'Opravdu smazat'} OnClick={() => this.DeleteRole()} /></div>
                            }
                        </div>
                        <Button className='col-2' text={"Upravit"} OnClick={() => this.EditRole()} />
                    </div>
                    <div className='d-flex flex-column mb-2 mt-3'>
                        {this.responseHelper.RenderDeleteResponse(this.state.responseDelete)}
                        {this.responseHelper.RenderEditResponse(this.state.responseEdit)}
                    </div>
                </div>
            </div>
        );
    }

}
