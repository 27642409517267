import React, { Component } from 'react';
import '@styles/adm/dashboard.scss';  

export class Dashboard extends Component {
    static displayName = Dashboard.name;

    constructor(props) {
        super(props);
        this.admin = this.props.loggedAdmin;
    }

    render() {
        return (
            <div id='admDashboard' className=''>
                <h1 className='mt-4'>Dashboard administrace</h1>
                <div className='d-flex flex-column mt-4'>
                    <div className='d-flex flex-column border-top borderRed pt-3 mb-3'>
                        <h4>Rozcestí</h4>
                        <div className='d-flex flex-column'>
                            <div className='d-flex'>
                                <div className='fw-bold me-1'>-</div>
                                <a href='/_adm/translations' className='fitW'>Správa překladů</a>
                            </div>
                            <div className='d-flex'>
                                <div className='fw-bold me-1'>-</div>
                                <a href='/_adm/users' className='fitW'>Správa uživatelů</a>
                            </div>
                            <div className='d-flex'>
                                <div className='fw-bold me-1'>-</div>
                                <a href='/_adm/careers' className='fitW'>Správa kariér</a>
                            </div>
                            <div className='d-flex'>
                                <div className='fw-bold me-1'>-</div>
                                <a href='/_adm/languages' className='fitW'>Správa jazyků</a>
                            </div>
                            <div className='d-flex'>
                                <div className='fw-bold me-1'>-</div>
                                <a href='/_adm/pages' className='fitW'>Správa stránek</a>
                            </div>
                            <div className='d-flex'>
                                <div className='fw-bold me-1'>-</div>
                                <a href='/_adm/images' className='fitW'>Správa obrázků</a>
                            </div>
                            <div className='d-flex'>
                                <div className='fw-bold me-1'>-</div>
                                <a href='/_adm/documents' className='fitW'>Správa dokumentů</a>
                            </div>
                            <div className='d-flex'>
                                <div className='fw-bold me-1'>-</div>
                                <a href='/_adm/slideShow' className='fitW'>Správa slideshow</a>
                            </div>
                        </div>
                    </div>
                    <div className='borderRed border-top pt-3 pb-3'>
                        <h4>Přihlášený uživatel</h4>
                        <div className='d-flex flex-column'>
                            <div>
                                <span className='fw-bold me-1'>Username:</span>
                                <span>{this.admin.username}</span>
                            </div>
                            {this.admin.description != null && this.admin.description.length > 0 &&
                                <div>
                                    <span className='fw-bold me-1'>Popis:</span>
                                    <span>{this.admin.description}</span>
                                </div>    
                            }
                            <div>
                                <span className='fw-bold me-1'>Založen:</span>
                                <span>{this.admin.createdAt}</span>
                            </div>
                            <div>
                                <span className='fw-bold me-1'>Přihlašení vyprší:</span>
                                <span>{this.admin.loginExpire}</span>
                            </div>
                        </div>
                    </div>
                    <div className='borderRed border-top pt-3'>
                        <a href='/' onClick={() => this.props.LogOff()}>Odhlásit</a>
                    </div>
                </div>
            </div>
        );
    }
}
