import LanguageListResponse from '@model/response/LanguageListResponse';
import TranslationListResponse from '@model/response/TranslationListResponse';
import SlideShowListResponse from '@model/response/SlideShowListResponse';

class LocalStorageHandler {

    constructor() {
        this.languageKey = 'currentLanguage';

        this.translationsKey = 'translations';
        this.scrollToKey = 'scrollTo';

        this.expectedTranslationStructure = {
            key: 'string',
            value: 'string'
        }

        this.languagesKey = 'languages';
        this.expectedLanguagesStructure = {
            name: 'string',
            code: 'string',
            imgPath: 'string'
        }

        this.loginTokenKey = 'token';

        this.expireTimestampKey = 'data_expire';
        this.dataDurationHours = 24;
        this.dataDurationMs = (this.dataDurationHours * 60 * 60 * 1000)

        this.dataExpireAlwaysKey = 'data_expire_always';

        this.slideShowKey = 'slideShow';
        this.expectedSlideShowStructure = {
            imgMobile: 'string',
            imgTablet: 'string',
            imgPc: 'string',
            imgPcLarge: 'string',
            heading: 'string',
            text: 'string',
            duration: 10,
            fontColor: 'string',
            captionMobileX: 'string',
            captionMobileY: 'string',            
            captionX: 'string',
            captionY: 'string'
        }
    }

    CheckCacheValidity = () => {

        if (!this.DataExpired()) // valid
            return;

        this.DataDelete();
        this.ExtendDataExpireTime();
    }

    ExtendDataExpireTime = () => {
        let extendedTime = Date.now() + this.dataDurationMs;
        localStorage.setItem(this.expireTimestampKey, extendedTime);
    }

    GetDataExpireForce() {
        try {
            let expire = parseInt(localStorage.getItem('data_expire_always'));
            return expire === 1;
        } catch {
            return false;
        }
    }

    GetDataExpireTimestamp() {

        let timestamp = localStorage.getItem(this.expireTimestampKey);

        timestamp = parseInt(timestamp, 10);
        if (!timestamp)
            timestamp = 0;

        return timestamp;
    }

    DataExpired() {
        let timestamp = this.GetDataExpireTimestamp();

        // cache cannot be set for longer than dataDuration
        if (timestamp > Date.now() + this.dataDurationMs)
            return true;

        return (timestamp < Date.now() || this.GetDataExpireForce());
    }

    DataDelete() {
        localStorage.removeItem(this.translationsKey);
        localStorage.removeItem(this.languagesKey);
        localStorage.removeItem(this.slideShowKey);
    }

    GetTranslations(language, page) {

        if (!language || !page) 
            return new TranslationListResponse([], 404);

        let cachedTranslations = localStorage.getItem(this.translationsKey);
        let parsedTranslations = JSON.parse(cachedTranslations);
        if (parsedTranslations == null || !parsedTranslations.hasOwnProperty(language) || !parsedTranslations[language].hasOwnProperty(page))
            return new TranslationListResponse([], 404);

        parsedTranslations = parsedTranslations[language][page];

        let isValid = this.StructureIsValid(parsedTranslations, this.expectedTranslationStructure) && parsedTranslations.length > 0;

        return isValid ? new TranslationListResponse(parsedTranslations, 200) : new TranslationListResponse([], 404);
    }

    SetTranslations(language, page, translations) {

        let cachedTranslations = localStorage.getItem(this.translationsKey)
        let parsedTranslations = JSON.parse(cachedTranslations);
        if (parsedTranslations == null)
            parsedTranslations = {};

        if (!parsedTranslations.hasOwnProperty(language))
            parsedTranslations[language] = {};

        if (!parsedTranslations[language].hasOwnProperty(page))
            parsedTranslations[language][page] = {};

        parsedTranslations[language][page] = translations;

        localStorage.setItem(this.translationsKey, JSON.stringify(parsedTranslations));
    }

    GetLoginToken() {
        return JSON.parse(localStorage.getItem(this.loginTokenKey));
    }

    SetLoginToken(token) {
        localStorage.setItem(this.loginTokenKey, JSON.stringify(token));
    }

    GetLanguage(validLanguages) {

        let languageCodes = validLanguages.map(obj => obj.code);
        let storedLanguage = localStorage.getItem(this.languageKey);

        if (storedLanguage == null || storedLanguage == undefined || languageCodes.indexOf(storedLanguage) == -1)
            return null;

        return storedLanguage;
    }

    SetLanguage(language) {
        localStorage.setItem(this.languageKey, language);
    }

    StructureIsValid(structure, expectedStructure) {

        if (!Array.isArray(structure))
            return false;

        return structure.every(obj => {
            return Object.keys(expectedStructure).every(key =>
                obj.hasOwnProperty(key) && (typeof obj[key] === typeof expectedStructure[key] || typeof obj[key] == typeof null)
            );
        });
    }

    GetLanguages() {

        let cachedLanguages = localStorage.getItem(this.languagesKey);
        try {

            const parsedLanguages = JSON.parse(cachedLanguages);
            let isValid = this.StructureIsValid(parsedLanguages, this.expectedLanguagesStructure) && parsedLanguages.length > 0;

            return isValid ? new LanguageListResponse(parsedLanguages, 200) : new LanguageListResponse([], 404);

        } catch (error) {
            return new LanguageListResponse([], 500);
        }
    }

    GetSlideShow() {

        let cachedSlideShow = localStorage.getItem(this.slideShowKey);
        try {

            const parsedSlideShow = JSON.parse(cachedSlideShow);
            let isValid = this.StructureIsValid(parsedSlideShow, this.expectedSlideShowStructure) && parsedSlideShow.length > 0;

            return isValid ? new SlideShowListResponse(parsedSlideShow, 200) : new SlideShowListResponse([], 404);

        } catch (error) {
            return new SlideShowListResponse([], 500);
        }
    }

    SetSlideShow(slideShow) {
        localStorage.setItem(this.slideShowKey, JSON.stringify(slideShow));
    }

    SetLanguages(languages) {
        localStorage.setItem(this.languagesKey, JSON.stringify(languages));
    }

    SetScrollTo(refName) {
        localStorage.setItem(this.scrollToKey, refName);
    }

    GetScrollTo() {
        return localStorage.getItem(this.scrollToKey) ?? '';
    }

    DeleteScrollTo() {
        localStorage.removeItem(this.scrollToKey);
    }
}

export default LocalStorageHandler;