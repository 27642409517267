
import React, { Component } from 'react';
import { NavBar } from '@components/shared/NavBar';
import { Loading } from '@components/shared/Loading';
import LoginHelper from '@model/helper/LoginHelper'
import { InfoMessage } from '@components/shared/InfoMessage'
import { BackButton } from '@components/shared/BackButton';

//pages
import { Pages } from '@components/adm/pages/pages/Pages';
import { Files } from '@components/adm/pages/files/Files';
import { Users } from '@components/adm/pages/users/Users';
import { Dashboard } from '@components/adm/pages/Dashboard';
import { Translations } from '@components/adm/pages/translations/Translations';
import { Careers } from '@components/adm/pages/careers/Careers';
import { Languages } from '@components/adm/pages/languages/Languages';
import { SlideShow } from '@components/adm/pages/slideShow/SlideShow';
import { LoginForm } from '@components/adm/pages/LoginForm';

import '@styles/adm/shared.scss'; 
export class PageController extends Component {
    static displayName = PageController.name;

    constructor(props) {
        super(props);

        this.state = {
            loggedIn: false,
            admin: null,
            loginExpired: false,
            loading: true,

            logOffResponse: null,

            navMenuPages: [],
        };

        this.loginHelper = new LoginHelper(); 
    }

    componentDidMount() {
        this.TokenLogin();
        document.title = "Sielaff ADM - Login";
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.loggedIn && !prevState.loggedIn) {

            let pages = [
                { name: "dashboard", link: "/_adm", linkName: "Dashboard", component: <Dashboard loggedAdmin={this.state.admin} LogOff={() => this.HandleLogOff()} /> },
                { name: "translations", link: "/_adm/translations", linkName: "Překlady", component: <Translations loggedAdmin={this.state.admin} /> },
                { name: "careers", link: "/_adm/careers", linkName: "Kariéry", component: this.CareersComponent() },
                { name: "slideShow", link: "/_adm/slideShow", linkName: "Slideshow", component: <SlideShow loggedAdmin={this.state.admin} /> },
                { name: "users", link: "/_adm/users", linkName: "Uživatelé", component: <Users loggedAdmin={this.state.admin} /> },
                { name: "languages", link: "/_adm/languages", linkName: "Jazyky", component: <Languages loggedAdmin={this.state.admin} /> },
                { name: "pages", link: "/_adm/pages", linkName: "Stránky", component: <Pages loggedAdmin={this.state.admin} /> },
                { name: "images", link: "/_adm/images", linkName: "Obrázky", component: <Files loggedAdmin={this.state.admin} type={'image'} /> },
                { name: "documents", link: "/_adm/documents", linkName: "Dokumenty", component: <Files loggedAdmin={this.state.admin} type={'document'} /> },
            ]

            this.setState((prevState) => ({
                ...prevState,
                navMenuPages: pages
            }));

            document.title = "Sielaff _adm - " + pages.find(p => p.name === this.props.page)?.linkName;
        }
    }

    CareersComponent = () => {
        return <div className='mh-100'>
            <Careers loggedAdmin={this.state.admin} noHeight />
            <Translations loggedAdmin={this.state.admin} noHeight forceCareer />
        </div>;
    }

    TokenLogin = () => {

        this.loginHelper.AdminTokenLogin().then(tokenLoginResponse => {

            this.setState((prevState) => ({
                ...prevState,
                loginExpired: tokenLoginResponse.responseCode == 440,
                loggedIn: tokenLoginResponse.responseCode == 200,
                admin: tokenLoginResponse.user,
                loading: false
            }));
        });
    }

    HandleSetLoggedUser = (admin) => {

        if (admin == null)
            return;

        this.setState({ ...this.state, loggedIn: true, admin: admin, logOffResponse: null });
    }

    RenderPageContent = () => {

        if (!this.state.loggedIn)
            return <LoginForm loginExpired={this.state.loginExpired} HandleSetLoggedUser={this.HandleSetLoggedUser} />;

        let dashboardPage = this.state.navMenuPages.find(nmp => nmp.name === "dashboard");
        if(!dashboardPage)
            return

        let targetPage = this.state.navMenuPages.find(nmp => nmp.name == this.props.page);
        if (!targetPage)
            return dashboardPage.component;

        return targetPage.component;
    }

    HandleClickBackButton = () => {
        window.location.href = '/_adm'
        return;
    }

    HandleLogOff = () => {

        let token = this.state.admin.loginToken;

        this.loginHelper.LogOff(token, this.state.admin.id).then(logOffResponse => {

            this.setState((prevState) => ({
                ...prevState,
                logOffResponse: logOffResponse.responseCode,
                loggedIn: logOffResponse.responseCode != 200,
            }));
        });
    }

    RenderLogOffMessage = () => {

        switch (this.state.logOffResponse) {
            case 200:
                return <InfoMessage text={"Odhlášení proběhlo úspěšně!"} isSuccess />
            case 400:
            case 401:
            case 440:
            case 500:
                return <InfoMessage text={"Odhlášení se nezdařilo!"} />
            default:
                return;
        }
    }

    render() {

        return (
            this.state.loading ? 
                <Loading />
                :
                <>
                    <NavBar isAdm isLoggedIn={this.state.loggedIn} LogOff={() => this.HandleLogOff()} currentPage={this.props.page} pages={this.state.navMenuPages} />
                    <div className={`${this.props.page == 'slideShow' ? '' : 'container'} position-relative`}>
                        {this.state.logOffResponse != null &&
                            <div className='position-absolute top-0 left-0'>
                                {this.RenderLogOffMessage()}
                            </div>    
                        }
                        {this.RenderPageContent()}
                    </div>
                </>
        );
    }
}
