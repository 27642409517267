import React, { Component } from 'react';
import '@styles/shared/spinner.scss'; 

export class Spinner extends Component {
    static displayName = Spinner.name;

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div id="spinner" className={`${this.props.small && 'small'} ${this.props.absolute && 'absolute'} ${this.props.dark && 'dark'} ${this.props.whiteBg && 'whiteBg'} ${this.props.grayBg && 'grayBg'}`}>
                <div className='base'>
                    <div className='spinner'></div>
                </div>
            </div>
        );
    }
}
