import React, { Component } from 'react';
import '@styles/adm/pages.scss';
import { Spinner } from '@components/shared/Spinner';
import { NewPage } from './components/NewPage';
import { EditPage } from './components/EditPage';
import axios from "axios";
import AdmResponseHelper from '@model/helper/AdmResponseHelper';
import { BreadCrumbs } from '@components/shared/BreadCrumbs';
import ShowMore from '@components/shared/ShowMore';

export class Pages extends Component {
    static displayName = Pages.name;

    constructor(props) {
        super(props);
        this.state = {

            pageList: [],
            response: null,

            newFormOpen: false,
            editFormOpen: false,
            editPageData: null,

            loading: true
        }

        this.responseHelper = new AdmResponseHelper();
        this.loginHeader = {
            headers: {
                'Header-Authority': JSON.stringify({ Token: this.props.loggedAdmin.loginToken })
            }
        };

        this.breadCrumbPath = [
            { text: "Správa stránek", link: null }
        ];
    }

    componentDidMount() {
        this.GetPageList();
    }

    GetPageList = () => {

        this.setState((prevState) => ({ ...prevState, loading: true }));

        return axios
            .get("/api/adm/page/list", this.loginHeader)
            .then((response) => {
                let data = response.data;
                console.log(data)
                this.setState((prevState) => ({ ...prevState, response: data.responseCode, pageList: data.pageList }));
            }).catch(() => {
                this.setState((prevState) => ({ ...prevState, response: 500 }));
            }).finally(() => {
                this.setState((prevState) => ({ ...prevState, loading: false }));
            });
    }

    RenderResponse = () => {

        switch (this.state.response) {
            case 400:
            case 401:
                return this.responseHelper.RenderLoginInvalid();
            case 500:
                return this.responseHelper.RenderSomethingWentWrong();
            default:
                return;
        }
    }

    HandleOpenNewForm = () => {
        this.setState({ ...this.state, newFormOpen: true, editFormOpen: false });
    }

    HandleCloseNewForm = () => {
        this.setState({ ...this.state, newFormOpen: false });
    }

    HandleOpenEditForm = (id) => {

        if (id == null)
            return;

        let targetPage = this.state.pageList.find(a => a.id === id);

        this.setState((prevState) => ({ ...prevState, editFormOpen: true, newFormOpen: false, editPageData: targetPage }));
    }

    HandleCloseEditForm = () => {
        this.setState({ ...this.state, editFormOpen: false });
    }

    RenderForms = () => {

        if (this.state.editFormOpen && this.state.editPageData == null)
            return

        return (
            (this.state.newFormOpen || this.state.editFormOpen) &&
            <div className='position-fixed h-100 formGlass d-flex justify-content-center pt-5'>
                {this.state.newFormOpen &&
                    <NewPage HandleCloseForm={() => this.HandleCloseNewForm()} loggedAdmin={this.props.loggedAdmin} GetPageList={() => this.GetPageList()} />
                }
                {this.state.editFormOpen &&
                    <EditPage HandleCloseForm={() => this.HandleCloseEditForm()} loggedAdmin={this.props.loggedAdmin} targetPage={this.state.editPageData} GetPageList={() => this.GetPageList()} />
                }
            </div>
        );
    }

    RenderPageList = () => {

        return (
            this.state.pageList.map((page) => {
                return <div className='col-12 d-flex border-top border-bottom align-items-center py-2' role='button' key={page.id} onClick={() => this.HandleOpenEditForm(page.id)}>
                    <div className='col-1'>{page.id}</div>
                    <div className='col-2 minW'>{page.name}</div>
                    <div className='col-2 '>{page.translationPrefix}</div>
                    <div className='col-2 '>
                        {page.isActive ?
                            <div className='d-flex align-items-center'>
                                Ano
                                <div className={`colorCircle green ms-1`}></div>
                            </div>
                            :
                            <div className='d-flex align-items-center'>
                                Ne
                                <div className={`colorCircle grayDarker ms-1`}></div>
                            </div>
                        }
                    </div>
                    <div className='col'>{page.internalNote}</div>
                    {!page.deletable && <small className='redText opacity-50 me-1'>Nesmazatelné</small>}
                </div>
            })
        );
    }

    render() {
        return (
            <div id='admPages' className='position-relative'>
                <BreadCrumbs
                    adm
                    path={this.breadCrumbPath}
                />
                <div className='mt-3 mb-2 d-flex flex-column flex-sm-row align-items-sm-center'>
                    <h1 className='m-0'>Správa stránek (kategorizace překladů)</h1>
                </div>
                {this.RenderResponse()}
                <ShowMore label={"Zobrazit více"} text={
                    <ul>
                        <li>Toto je výčet možných typů překladů, každý překlad musí mít přiřazen právě jeden typ.</li>
                        <li>Překlady se načítají na různých místech aplikace, dle jeho přiřazeného typu.</li>
                        <li>Například překlady vázané na stránku 'shared' se načítají a jsou dostupné na každé veřejné stránce aplikace, překlady vázané na 'home' jsou načítány jen na hlavní stránce aplikace atd.</li>
                        <li>Klíče jednotlivých překladů poté musí začínat prefixem vázanho typu.</li>
                    </ul>
                } />  
                <div className='mt-4'>
                    <div className='position-relative'>
                        {this.state.loading ?
                            <Spinner dark />
                            :
                            <>
                                <div className='col-12 d-flex mt-3'>
                                    <div className='col-1 fw-bold'>ID</div>
                                    <div className='col-2 fw-bold minW'>Název</div>
                                    <div className='col-2 fw-bold'>Prefix překladu</div>
                                    <div className='col-2 fw-bold'>Aktivní</div>
                                    <div className='col fw-bold'>Popis</div>
                                </div>
                                <div className='col-12 pageList mb-4'>
                                    {this.RenderPageList()}
                                </div>
                            </>
                        }
                    </div>
                </div>
                {this.RenderForms()}
            </div>
        );
    }
}
