import React, { Component } from 'react';
import '@styles/shared/pageError.scss'; 
import { NavBar } from '@components/shared/NavBar';

export class PageError extends Component {
    static displayName = PageError.name;

    constructor(props) {
        super(props);
    }

    RenderText = () => {

        let language = this.props.currentLanguage;
        if (!language) {
            language = navigator.language.substring(0, 2).toLowerCase();
        }

        switch (language) {
            case 'en':
                return <>
                    <h1 className='mb-2'>Something went wrong. The page failed to load.</h1>
                    <h2>Sorry for the inconvenience, please try again later.</h2>
                </>
            case 'de':
                return <>
                    <h1 className='mb-2'>Etwas ist schiefgelaufen. Die Seite konnte nicht geladen werden.</h1>
                    <h2>Entschuldigen Sie die Unannehmlichkeiten, bitte versuchen Sie es später noch einmal.</h2>
                </>
            default:
                return <>
                    <h1 className='mb-2'>Něco se pokazilo. Stránku se nepodařilo načíst.</h1>
                    <h2>Omlouváme se za potíže, zkuste to prosím později.</h2>
                </>
        }
    }

    render() {
        return (
            <div>
                <NavBar
                    currentLanguage={this.props.currentLanguage}
                    languagesImages={this.props.languagesImages}
                    changeLanguage={this.props.ChangeLanguage}
                    HandleLinkClick={this.props.HandleLinkClick}
                    isErrorPage
                />
                <div id='pageError' className='position-relative container pt-5'>
                    {this.RenderText()}
                    <div className='bug position-absolute questionmark'>
                        <i className="fa-solid fa-bug"></i>
                    </div>
                </div>              
            </div>
        );
    }
}
