import React, { Component } from 'react';
import '@styles/shared/loading.scss'; 

export class Loading extends Component {
    static displayName = Loading.name;

    render() {
        return (
            <div className={`loadingBody ${this.props.fitNav ? 'fitNav' : ''} ${this.props.absolute ? 'absolute' : ''} ${this.props.white ? 'white' : ''}`}>
                {!this.props.blank &&
                    <div className='loading'>
                        <img src='./files/images/logo.png' alt='Logo Sielaff Bohemia' />
                        <div className='bottom-shadow'></div>
                    </div>    
                }
            </div>
        );
    }
}
