
import React, { Component } from "react";
import { useParams } from "react-router-dom";

import PageHelper from '@model/helper/PageHelper';
import { Button } from '@components/shared/Button';
import { Loading } from '@components/shared/Loading';
import '@styles/public/career.scss';
import { BreadCrumbs } from '@components/shared/BreadCrumbs';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

export class Career extends Component {
    static displayName = Career.name;

    constructor(props) {
        super(props);

        this.controller = new PageHelper();
        this.translate = this.props.translate;
        this.translateRaw = this.props.translateRaw;

        this.state = {
            name: "",
            career: null,
            response: null,
            breadCrumbPath: [
                { text: this.translate("s_navlink_career"), link: this.props.navLinks.career.main }
            ],
        };
    }

    componentDidMount() {

        this.controller.GetCareerDetail(this.props.language, this.props.params.name).then((careerDetailResponse) => {

            if (localStorage.getItem("debug") === "1")
                console.log(careerDetailResponse);

            this.setState((prevState) => ({
                ...prevState,
                response: careerDetailResponse.responseCode,
                career: careerDetailResponse.career,
            }));

            if (careerDetailResponse.responseCode != 200)
                return;

            this.setState((prevState) => ({
                ...prevState,
                breadCrumbPath: [
                    { text: this.translate("s_navlink_career"), link: this.props.navLinks.career.main },
                    { text: careerDetailResponse.career.translationName, link: "" }
                ],
            }));

            document.title = `Sielaff Bohemia - ${this.props.translateRaw("s_navlink_career")} ${careerDetailResponse.career.translationName.toLowerCase()}`;
        });
    }

    RenderCareer = () => {
        return (
            <div className='d-lg-flex justify-content-between'>
                <div className='information me-lg-4'>
                    <h1 className=''>{this.state.career.translationName}</h1>
                    <div className='item'>
                        <h3 className=''>{this.translate("c_branch_heading")}</h3>
                        <p>{this.state.career.translationBranch}</p>
                    </div>
                    <div className='item'>
                        <h3 className=''>{this.translate("c_description_heading")}</h3>
                        <p>{this.state.career.translationDescription}</p>
                    </div>
                    <div className='item'>
                        <h3 className=''>{this.translate("c_location_heading")}</h3>
                        <p>{this.state.career.translationLocation}</p>
                    </div>
                    {this.state.career.translationAdditionalInfo &&
                        <div className='item'>
                                <h3 className=''>{this.translate("c_additional_info_heading")}</h3>
                            <p>{this.state.career.translationAdditionalInformation}</p>
                        </div>
                    }
                    {this.state.career.translationBenefits &&
                        <div className='item'>
                            <h3 className=''>{this.translate("c_benefits_heading")}</h3>
                            <p>{this.state.career.translationBenefits}</p>
                        </div>
                    }
                </div>
                <div className='contactUs p-4 mx-auto mx-lg-0 d-flex flex-column col-lg-5'>
                    <div className='d-flex flex-column'>
                        <h3 className='fw-bold d-flex mb-3'>{this.translate("c_contact_us")}</h3>
                        <div className='d-flex flex-column flex-md-row flex-lg-column flex-xl-row'>
                            <img src='./files/images/contact_us.png' alt='Kontaktujte nás!' className='ms-auto me-auto me-md-0 personImage order-md-1 me-lg-auto order-xl-3' />
                            <div className='d-flex align-items-center justify-content-center mt-2 mt-md-0 order-lg-2 mt-lg-2 mt-xl-0 me-xl-2'>
                                <div>
                                    <span className='fw-bold'>Zuzana Spěváčková</span>
                                    <div className='d-flex align-items-center'>
                                        <img src='/files/images/phone_icon.svg' className='icon' alt='Phone icon' />
                                        <span>736 514 495</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mt-3 ms-auto'>
                        <Button text={this.translate("c_im_interested")} OnClick={() => this.props.HandleLinkClick(this.props.navLinks.contact.subLinks.contactForm)} />
                    </div>
                </div>
            </div>
        );
    }

    RenderServerError = () => {
        return (
            <>
                <h1 className='mb-2'>{this.translate("s_something_went_wrong")}</h1>
                <h3>{this.translate("s_wait_and_try_again")}</h3>
            </>
        );
    }

    RenderCareerInvalid = () => {
        return (
            <>
                <h1 className='mb-2'>{this.translate("c_not_found")}</h1>
                <h3>{this.translate("s_wrong_address")}</h3>
            </>
        );
    }

    RenderPageContent = () => {
        switch (this.state.response) {
            case 200:
                return this.RenderCareer();
            case 400:
            case 404:
                return this.RenderCareerInvalid();
            case 500:
                return this.RenderServerError();
            default:
                return <Loading fitNav absolute white blank />;
        }
    }

    render() {
        return (
            <section id='career' className='container p-3 p-md-4 position-relative'>
                <BreadCrumbs
                    translate={this.translate}
                    navLinks={this.props.navLinks}
                    HandleLinkClick={this.props.HandleLinkClick}
                    path={this.state.breadCrumbPath}
                />
                <div className='mt-4 mt-md-5'>
                    {this.RenderPageContent()}
                </div>
            </section>
        );
    }
}

export default withParams(Career);